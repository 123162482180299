import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, resetAuth } from '../../features/auth/authSlice';
import { clearPortfolio } from '../../features/portfolio/portfolioSlice';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Logo from '../../assets/logo.png';
import Sidebar from './Sidebar';

const Appbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const isMobile = useMediaQuery('(max-width: 900px)');

  const onLogout = () => {
    dispatch(logout(token._id));
    dispatch(resetAuth());
    dispatch(clearPortfolio());
    navigate('/login');
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const profileMenuId = 'profile-menu';
  const renderProfileMenu = (
    <Menu
      id={profileMenuId}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ mt: '40px' }}
    >
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          navigate('/profile');
        }}
      >
        Profile
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          onLogout();
        }}
      >
        Log Out
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position='fixed'
        enableColorOnDark
        color='background'
        sx={{
          backgroundColor: 'rgb(8, 18, 52)',
          backgroundImage: 'none',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <Container maxWidth='100%'>
          <Toolbar
            variant='dense'
            sx={{ padding: 0, justifyContent: 'space-between', minHeight: { xs: '66px', md: '68px' } }}
          >
            {(!isMobile || !token) && (
              <Link to={`${token ? '/dashboard' : '/'}`}>
                <Box
                  component='img'
                  src={Logo}
                  alt='eulerFX'
                  sx={{
                    width: { xs: '100px', md: '138px' },
                    cursor: 'pointer'
                  }}
                ></Box>
              </Link>
            )}

            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              sx={{ display: { xs: 'flex', md: 'none' } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                display: { xs: 'none', md: 'flex' },
                gap: 2,
                marginRight: '1rem'
              }}
            >
              {token ? (
                <>
                  <Link to='/portfolio'>
                    <Button sx={{ my: 2, color: 'white', display: 'block' }}>Portfolio</Button>
                  </Link>
                  <Link to='/myfund'>
                    <Button sx={{ my: 2, color: 'white', display: 'block' }}>My Fund</Button>
                  </Link>
                  <Typography color='primary' sx={{ my: 2, pl: 6, alignSelf: 'center' }}>
                    Welcome, {token.name?.split(' ')[0]}!
                  </Typography>
                </>
              ) : (
                <>
                  <Link to='/#features'>
                    <Button sx={{ my: 2, color: 'white', display: 'flex', marginRight: '1rem' }}>Features</Button>
                  </Link>
                  <Link to='/#faq'>
                    <Button sx={{ my: 2, color: 'white', display: 'flex', marginRight: '1rem' }}>FAQ</Button>
                  </Link>

                  <Divider variant='middle' orientation='vertical' flexItem sx={{ marginRight: '1rem' }} />

                  <Link to='/login'>
                    <Button
                      sx={{
                        my: 2,
                        color: 'white',
                        marginRight: '30px',
                        fontSize: '16px',
                        fontWeight: 600,
                        textTransform: 'none'
                      }}
                      startIcon={<LoginIcon />}
                    >
                      Log In
                    </Button>
                  </Link>
                  <Link to='/register'>
                    <Button
                      variant='contained'
                      sx={{
                        display: 'flex',
                        my: 2,
                        borderRadius: '25px',
                        color: '#111111',
                        fontSize: '16px',
                        fontWeight: 600,
                        textTransform: 'none'
                      }}
                      startIcon={<HowToRegIcon />}
                    >
                      Sign Up
                    </Button>
                  </Link>
                </>
              )}
            </Box>

            {token && (
              <Box sx={{ display: { xs: 'flex' }, alignItems: 'center', gap: 2 }}>
                <Typography color='primary' sx={{ display: { md: 'none' }, my: 2, alignSelf: 'center' }}>
                  Welcome, {token.name?.split(' ')[0]}!
                </Typography>
                <IconButton
                  edge='end'
                  aria-label='My Account'
                  aria-controls={profileMenuId}
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                  color='inherit'
                >
                  <Avatar
                    alt={token.name}
                    sx={{ bgcolor: '#90caf9', width: { xs: 26, md: 32 }, height: { xs: 26, md: 32 } }}
                  >
                    {token.name?.split('')[0]}
                  </Avatar>
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Sidebar setIsClosing={setIsClosing} setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />

      {renderProfileMenu}
    </>
  );
};

export default Appbar;
