import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetAuth } from '../../features/auth/authSlice';
import {
  isEmptyObject,
  isDomestic,
  tooltipStyle,
  TICKCOLOR_UP,
  TICKCOLOR_DOWN,
  TICKCOLOR_NEUTRAL
} from '../../lib/utils';
import BuyAsset from './BuyAsset';
import SellAsset from './SellAsset';

import { Box, Stack, Typography, Link, Tooltip, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PremiumLogicList = ({ premiumLogic, trendType, country }) => {
  const dispatch = useDispatch();
  const [picks, setPicks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { preferences } = useSelector(state => state.auth);
  const { portfolio, portfolioUS } = useSelector(state => state.portfolio);
  const isTrendDown = trendType === 'DOWN';
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (
      isTrendDown &&
      ((isDomestic(country) && isEmptyObject(portfolio)) || (!isDomestic(country) && isEmptyObject(portfolioUS)))
    ) {
      return;
    }

    const controller = new AbortController();

    setIsLoading(true);

    (async () => {
      const tickers = [];

      if (isTrendDown) {
        if (isDomestic(country)) {
          portfolio?.forEach(asset => {
            tickers.push(asset.pdno);
          });
        } else {
          portfolioUS?.forEach(asset => {
            tickers.push(asset.ovrs_pdno);
          });
        }
      }

      const API_URL = '/api/lists/';

      // set request query params
      const config = {
        params: {
          premiumLogic,
          trendType,
          tickers,
          signal: controller.signal
        }
      };

      const response = await axios.get(API_URL + 'premiumlogic', config);

      const assets = response.data.map(asset => {
        const idx = isDomestic(country)
          ? portfolio.findIndex(el => el.pdno === asset.shcode)
          : portfolioUS.findIndex(el => el.ovrs_pdno === asset.shcode);

        if (idx !== -1) {
          asset.quantity = isDomestic(country) ? portfolio[idx].hldg_qty : portfolioUS[idx].ovrs_cblc_qty;
        }

        const data = {
          diff: asset.close - asset.yclose,
          diffRatio: ((asset.close - asset.yclose) / asset.yclose) * 100,
          type: asset.asset_type
        };

        return { ...asset, ...data };
      });

      setPicks(assets);
      setIsLoading(false);
    })();

    return () => {
      controller.abort('axios call aborted!');
      dispatch(resetAuth());
    };
  }, [portfolio, portfolioUS, dispatch, premiumLogic, trendType, isTrendDown, country]);

  const CustomNoRowsOverlay = () => (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      No assets.
    </Box>
  );

  const renderAssetName = params => {
    return isDomestic(country) ? (
      <Link
        color='primary.light'
        underline='none'
        href={`https://finance.naver.com/item/main.naver?code=${params.row.asset.shcode}`}
        target='_blank'
        rel='noreferrer'
      >
        {params.value}
      </Link>
    ) : (
      <Tooltip disableFocusListener arrow title={params.row.asset.hname} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.yahoo.com/quote/${params.value}?p=${params.value}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.value}
        </Link>
      </Tooltip>
    );
  };

  const renderChange = params => {
    return (
      <Typography
        variant='body2'
        display='flex'
        alignItems='center'
        color={params.value > 0 ? TICKCOLOR_UP : params.value < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL}
      >
        {params.value > 0 ? <ArrowDropUpIcon /> : params.value < 0 ? <ArrowDropDownIcon /> : ''}
        {isDomestic(country)
          ? Math.abs(params.value).toLocaleString('en-us')
          : `$${Math.abs(params.value).toFixed(2).toLocaleString('en-us')}`}
      </Typography>
    );
  };

  const renderChangeRatio = params => {
    return (
      <Typography
        variant='body2'
        display='flex'
        alignItems='center'
        color={params.value > 0 ? TICKCOLOR_UP : params.value < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL}
      >
        {params.value > 0 ? <ArrowDropUpIcon /> : params.value < 0 ? <ArrowDropDownIcon /> : ''}
        {Math.abs(params.value).toFixed(1).toLocaleString('en-us')}%
      </Typography>
    );
  };

  const renderMarketCap = params => {
    return isDomestic(country)
      ? Number(params.value / 10).toLocaleString('en-us') + 'B'
      : '$' + Number(params.value / 10).toLocaleString('en-us') + 'B';
  };

  const renderTradeButton = params => {
    return (
      <Stack direction='row' spacing={1}>
        {isTrendDown ? (
          <SellAsset asset={params.row.asset} country={country} />
        ) : (
          <BuyAsset asset={params.row.asset} country={country} />
        )}
      </Stack>
    );
  };

  const renderCellColor = params => {
    const myAsset = isDomestic(country)
      ? portfolio.filter(asset => asset.pdno === params.row.asset.shcode)
      : portfolioUS.filter(asset => asset.ovrs_pdno === params.row.asset.shcode);

    return myAsset.length !== 0 ? 'my-asset' : '';
  };

  const columns = [
    {
      field: 'assetName',
      headerName: 'Asset',
      headerAlign: 'center',
      align: 'center',
      flex: isMobile ? 0.3 : 0.6,
      renderCell: renderAssetName,
      cellClassName: renderCellColor
    },
    {
      field: 'assetQuantity',
      headerName: 'Quantity',
      headerAlign: 'center',
      align: 'center',
      flex: 0.4,
      sortable: false,
      cellClassName: renderCellColor
    },
    {
      field: 'assetLastPrice',
      headerName: 'Close',
      headerAlign: 'center',
      align: 'center',
      flex: isMobile ? 0.2 : 0.4,
      sortable: false,
      cellClassName: renderCellColor
    },
    {
      field: 'assetChange',
      headerName: 'Change',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: isMobile ? 0.2 : 0.4,
      renderCell: renderChange,
      cellClassName: renderCellColor
    },
    {
      field: 'assetChangeRatio',
      headerName: '% Change',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.4,
      renderCell: renderChangeRatio,
      cellClassName: renderCellColor
    },
    {
      field: 'assetMarketCap',
      headerName: 'Market Cap',
      headerAlign: 'center',
      align: 'center',
      flex: 0.4,
      renderCell: renderMarketCap,
      cellClassName: renderCellColor
    },
    {
      field: 'upTick',
      headerName: 'Up Tick',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      cellClassName: renderCellColor
    },
    {
      field: 'tradeBtn',
      headerName: 'Trade',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.3,
      sortable: false,
      renderCell: renderTradeButton,
      cellClassName: renderCellColor
    }
  ];
  const rows = [];

  picks.forEach((asset, idx) => {
    const assetInfo = { ...asset };
    let assetPreferences = [];

    if (isTrendDown) {
      const prefData = preferences.assets || [];
      const found = Object.entries(prefData).find(entry => entry[0] === asset.shcode);

      if (found) {
        const data = Object.entries(found[1]).map(entry => {
          if (entry[1] === true) return entry[0];
          else return null;
        });

        assetPreferences = data.filter(el => el !== null);
      }
    }

    const data = {
      id: idx + 1,
      assetName: isDomestic(country) ? asset.hname : asset.shcode,
      assetQuantity: isTrendDown ? asset.quantity : 0,
      assetLastPrice: isDomestic(country)
        ? Number(asset.close).toLocaleString('en-us')
        : `$${Number(asset.close).toFixed(2).toLocaleString('en-us')}`,
      assetChange: asset.diff,
      assetChangeRatio: asset.diffRatio,
      assetMarketCap: asset.marketcap,
      upTick: Number(asset.tt_sig).toFixed(1),
      asset: assetInfo,
      assetPreferences: assetPreferences
    };

    rows.push(data);
  });

  return (
    <Box sx={{ width: '100%', '& .my-asset': { backgroundColor: '#132f4c' } }}>
      <DataGrid
        dense
        autoHeight
        rows={rows}
        columns={columns}
        loading={isLoading}
        sortingOrder={['asc', 'desc']}
        disableRowSelectionOnClick
        disableColumnMenu
        columnVisibilityModel={{
          assetName: true,
          assetQuantity: false,
          assetLastPrice: true,
          assetChange: true,
          assetChangeRatio: !isMobile ? true : false,
          assetMarketCap: !isMobile ? true : false,
          upTick: !isMobile ? true : false,
          tradeBtn: true
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[10]}
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
          '& .MuiDataGrid-columnHeader': { backgroundColor: '#001021' }
        }}
      />
    </Box>
  );
};

export default PremiumLogicList;
