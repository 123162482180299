import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { searchTickers } from '../../api/searchTickers';
import { fetchSignals } from '../../api/fetchSignals';
import { isDomestic, TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL } from '../../lib/utils';
import { Autocomplete, Box, Chip, Container, Link, Stack, TextField, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddFund from './AddFund';

const SearchAsset = () => {
  const navigate = useNavigate();

  const { token, credentials, preferences } = useSelector(state => state.auth);
  const { appkey, appsecret } = credentials;
  const tradingMode = preferences.tradingMode;

  const [tickers, setTickers] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);

  const tableStyle = {
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    lineHeight: '52px',
    paddingLeft: '1rem'
  };

  const handleChange = async e => {
    if (typeof e.target.value === 'string') {
      if (e.target.value.length === 0) setTickers([]);
      else {
        const tickers = await searchTickers(token, tradingMode, e.target.value);

        setTickers(tickers);
      }
    }
  };

  const getAssetType = async ticker => {
    const API_URL = `/api/${tradingMode.toLowerCase()}/assets/type/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return response.data[0].type;
    } catch (error) {
      console.log(error.response.data);
      return 0;
    }
  };

  const getAssetInfo = async (country, ticker, marketCode) => {
    const API_URL = `/api/${tradingMode.toLowerCase()}/assets/price/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        },
        params: {
          userId: token._id,
          appkey,
          appsecret,
          country,
          marketCode
        }
      };

      if (isDomestic(country)) {
        const assetType = await getAssetType(ticker);

        if (assetType === 3) {
          ticker = 'Q' + ticker;
        }
      }

      const response = await axios.get(API_URL + ticker, config);

      return response.data;
    } catch (error) {
      console.log(error.response);
      return `Error::${error.code}`;
    }
  };

  const getSignal = async (country, ticker) => {
    const tickers = [{ country, ticker }];
    const signal = await fetchSignals(token, tradingMode, tickers);

    return signal;
  };

  const displayDetails = async ticker => {
    const country = ticker.country;
    const marketCode = ticker.marketCode || null;

    const { output } = await getAssetInfo(country, ticker.ticker, marketCode);
    const signal = await getSignal(country, ticker.ticker);

    let tick, tickColor, openColor, highColor, lowColor;

    if (isDomestic(country)) {
      tick = ['1', '2'].includes(output.prdy_vrss_sign)
        ? 'UP'
        : ['4', '5'].includes(output.prdy_vrss_sign)
        ? 'DOWN'
        : 'HOLD';
      tickColor = ['1', '2'].includes(output.prdy_vrss_sign)
        ? TICKCOLOR_UP
        : ['4', '5'].includes(output.prdy_vrss_sign)
        ? TICKCOLOR_DOWN
        : TICKCOLOR_NEUTRAL;
      openColor =
        Number(output.stck_oprc) - Number(output.stck_sdpr) > 0
          ? TICKCOLOR_UP
          : Number(output.stck_oprc) - Number(output.stck_sdpr) < 0
          ? TICKCOLOR_DOWN
          : TICKCOLOR_NEUTRAL;
      highColor =
        output.stck_hgpr - output.stck_sdpr > 0
          ? TICKCOLOR_UP
          : output.stck_hgpr - output.stck_sdpr < 0
          ? TICKCOLOR_DOWN
          : TICKCOLOR_NEUTRAL;
      lowColor =
        output.stck_lwpr - output.stck_sdpr > 0
          ? TICKCOLOR_UP
          : output.stck_lwpr - output.stck_sdpr < 0
          ? TICKCOLOR_DOWN
          : TICKCOLOR_NEUTRAL;
    } else {
      tick = output.last - output.base > 0 ? 'UP' : output.last - output.base < 0 ? 'DOWN' : 'HOLD';
      tickColor =
        output.last - output.base > 0
          ? TICKCOLOR_UP
          : output.last - output.base < 0
          ? TICKCOLOR_DOWN
          : TICKCOLOR_NEUTRAL;
      openColor =
        output.open - output.base > 0
          ? TICKCOLOR_UP
          : output.open - output.base < 0
          ? TICKCOLOR_DOWN
          : TICKCOLOR_NEUTRAL;
      highColor =
        output.high - output.base > 0
          ? TICKCOLOR_UP
          : output.high - output.base < 0
          ? TICKCOLOR_DOWN
          : TICKCOLOR_NEUTRAL;
      lowColor =
        output.low - output.base > 0 ? TICKCOLOR_UP : output.low - output.base < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL;
      // tickColor = output.last - output.base > 0 ? '#66bb6a' : output.last - output.base < 0 ? '#f44336' : '#ffffff';
      // openColor = output.open - output.base > 0 ? '#66bb6a' : output.open - output.base < 0 ? '#f44336' : '#ffffff';
      // highColor = output.high - output.base > 0 ? '#66bb6a' : output.high - output.base < 0 ? '#f44336' : '#ffffff';
      // lowColor = output.low - output.base > 0 ? '#66bb6a' : output.low - output.base < 0 ? '#f44336' : '#ffffff';
    }

    setAssetDetails({
      name: ticker.name,
      ticker: ticker.ticker,
      price: isDomestic(country)
        ? Number(output.stck_prpr).toLocaleString('en-us')
        : `$${Number(output.last).toFixed(2).toLocaleString('en-us')}`,
      change: isDomestic(country)
        ? Math.abs(output.prdy_vrss).toLocaleString('en-us')
        : `$${Math.abs(output.last - output.base)
            .toFixed(2)
            .toLocaleString('en-us')}`,
      changeRatio: isDomestic(country)
        ? `${Math.abs(output.prdy_ctrt).toLocaleString('en-us')}%`
        : `${((Math.abs(output.last - output.base) / output.base) * 100).toFixed(2).toLocaleString('en-us')}%`,
      open: isDomestic(country)
        ? Number(output.stck_oprc).toLocaleString('en-us')
        : output.open
        ? `$${Number(output.open).toFixed(2).toLocaleString('en-us')}`
        : 'N/A',
      close: isDomestic(country)
        ? Number(output.stck_sdpr).toLocaleString('en-us')
        : output.base
        ? `$${Number(output.base).toFixed(2).toLocaleString('en-us')}`
        : 'N/A',
      high: isDomestic(country)
        ? Number(output.stck_hgpr).toLocaleString('en-us')
        : output.high
        ? `$${Number(output.high).toFixed(2).toLocaleString('en-us')}`
        : 'N/A',
      low: isDomestic(country)
        ? Number(output.stck_lwpr).toLocaleString('en-us')
        : output.low
        ? `$${Number(output.low).toFixed(2).toLocaleString('en-us')}`
        : 'N/A',
      high52: isDomestic(country)
        ? Number(output.w52_hgpr).toLocaleString('en-us')
        : output.h52p
        ? `$${Number(output.h52p).toFixed(2).toLocaleString('en-us')}`
        : 'N/A',
      low52: isDomestic(country)
        ? Number(output.w52_lwpr).toLocaleString('en-us')
        : output.l52p
        ? `$${Number(output.l52p).toFixed(2).toLocaleString('en-us')}`
        : 'N/A',
      volume: isDomestic(country)
        ? Number(output.acml_vol).toLocaleString('en-us')
        : output.tvol
        ? Number(output.tvol).toLocaleString('en-us')
        : 'N/A',
      marketCap: isDomestic(country)
        ? `${Math.ceil(output.hts_avls / 10).toLocaleString('en-us')}B`
        : output.tomv
        ? `$${Math.ceil(output.tomv / 1_000_000_000).toLocaleString('en-us')}B`
        : 'N/A',
      signal: signal ? signal[0].signal : 2,
      marketCode,
      country,
      tick,
      tickColor,
      openColor,
      highColor,
      lowColor
    });
  };

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }
  }, [token, navigate]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '720px' }}
        sx={{ width: '100%', margin: 'auto', mt: 8, typography: 'body1' }}
      >
        <Stack spacing={2} sx={{ width: '100%', margin: 'auto' }}>
          <Autocomplete
            id='stock_search_autocomplete'
            freesolo='true'
            clearOnEscape
            clearOnBlur
            filterOptions={x => x}
            getOptionLabel={option => option.name}
            options={tickers}
            isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
            noOptionsText={'No matching asset found'}
            renderOption={(props, option) => {
              return (
                <Box component={'li'} {...props} key={option.ticker}>
                  {option.name} ({option.ticker})
                </Box>
              );
            }}
            renderInput={params => <TextField {...params} label='Search an asset...' />}
            onChange={(e, newValue) => {
              if (newValue !== null) {
                displayDetails(newValue);
              }
            }}
            onInputChange={debounce(handleChange, 300)}
          />
        </Stack>

        {assetDetails && (
          <Stack spacing={4} sx={{ width: '100%', margin: { xs: '2rem auto', md: '4rem auto' } }}>
            <Box display={'flex'} gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
              <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
                {assetDetails.country === 'KOREA' ? (
                  <Link
                    color='primary.light'
                    underline='none'
                    href={`https://finance.naver.com/item/main.naver?code=${assetDetails.ticker}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Typography color={'#90caf9'}>{assetDetails.name}</Typography>
                  </Link>
                ) : (
                  <Link
                    color='primary.light'
                    underline='none'
                    href={`https://finance.yahoo.com/quote/${assetDetails.ticker}?p=${assetDetails.ticker}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Typography color={'#90caf9'}>{assetDetails.name}</Typography>
                  </Link>
                )}
                <Typography>({assetDetails.ticker})</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={2} justifyContent={'space-between'} width={'100%'}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography color={assetDetails.tickColor}>{assetDetails.price}</Typography>
                  <Typography color={assetDetails.tickColor} display={'flex'} alignItems={'center'}>
                    {assetDetails.tick === 'UP' ? (
                      <ArrowDropUpIcon />
                    ) : assetDetails.tick === 'DOWN' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      ''
                    )}
                    {assetDetails.change.toLocaleString('en-us')} ({assetDetails.changeRatio})
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={4}>
                  {assetDetails.signal === 0 ? (
                    <Chip label='SELL' color='success' sx={{ width: '62px' }} />
                  ) : assetDetails.signal === 1 ? (
                    <Chip label='BUY' color='error' sx={{ width: '62px' }} />
                  ) : assetDetails.signal === 2 ? (
                    <Chip label='HOLD' color='secondary' sx={{ width: '62px' }} />
                  ) : assetDetails.signal === 99 ? (
                    <Chip label='DELISTED' />
                  ) : (
                    ''
                  )}
                  {assetDetails.signal !== 99 && <AddFund asset={assetDetails} />}
                </Box>
              </Box>
            </Box>

            <Box
              display={'grid'}
              gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
              border={'1px solid rgba(255, 255, 255, 0.2)'}
              borderRadius={'6px'}
            >
              <Typography style={tableStyle} backgroundColor='#001021'>
                Prev Close
              </Typography>
              <Typography style={tableStyle}>{assetDetails.close}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Open
              </Typography>
              <Typography style={tableStyle} color={assetDetails.openColor}>
                {assetDetails.open}
              </Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                High
              </Typography>
              <Typography style={tableStyle} color={assetDetails.highColor}>
                {assetDetails.high}
              </Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Low
              </Typography>
              <Typography style={tableStyle} color={assetDetails.lowColor}>
                {assetDetails.low}
              </Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                52W High
              </Typography>
              <Typography style={tableStyle}>{assetDetails.high52}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                52W Low
              </Typography>
              <Typography style={tableStyle}>{assetDetails.low52}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Volume
              </Typography>
              <Typography style={tableStyle}>{assetDetails.volume}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Market Cap
              </Typography>
              <Typography style={tableStyle}>{assetDetails.marketCap}</Typography>
            </Box>
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default SearchAsset;
