import axios from 'axios';

const API_URL = '/api/users/';

// Register user
const register = async userData => {
  try {
    const response = await axios.post(API_URL, userData);

    return response.data;
  } catch (error) {
    console.log(error.response);
    throw error;
  }
};

// Login user
const login = async userData => {
  try {
    const response = await axios.post(API_URL + 'login', userData);

    if (!response.data) {
      throw new Error('Login failed');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Logout user
const logout = async userId => {
  return 'Logged out!';
};

// Forgot password
const forgotPassword = async email => {
  try {
    const response = await axios.post(API_URL + 'forgotpassword', email);

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    return response.data;
  } catch (error) {
    // console.log(error.response);
    throw error;
  }
};

// Forgot password
const resetPassword = async (password, resetToken) => {
  try {
    const data = {
      password,
      resetToken
    };

    const response = await axios.post(API_URL + 'resetpassword', data);

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    return response.data;
  } catch (error) {
    console.log(error.response);
    throw error;
  }
};

// Set user profile
const setProfile = async (token, userId, profile) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };

    const data = {
      userId,
      profile
    };

    const response = await axios.post(API_URL + 'profile', data, config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

// Get user credentials
const getCredentials = async (token, userId, tradingMode) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      },
      params: {
        userId,
        tradingMode
      }
    };

    const response = await axios.get(API_URL + 'kis', config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

// Set user credentials
const setCredentials = async (token, userId, credentials, tradingMode) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };

    const data = {
      userId,
      credentials,
      tradingMode
    };

    const response = await axios.post(API_URL + 'kis', data, config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

// Get user preferences
const getPreferences = async (token, userId) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      },
      params: { userId }
    };

    const response = await axios.get(API_URL + 'preferences', config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

// Set user preferences
const setPreferences = async (token, userId, preferences) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };

    const data = {
      userId,
      preferences
    };

    const response = await axios.post(API_URL + 'preferences', data, config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

// Get user preferences
const getMyFunds = async (token, userId) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      },
      params: { userId }
    };

    const response = await axios.get(API_URL + 'funds', config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

// Set user preferences
const setMyFunds = async (token, userId, myFunds) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };

    const data = {
      userId,
      myFunds
    };

    const response = await axios.post(API_URL + 'funds', data, config);

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
};

const authService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  setProfile,
  getCredentials,
  setCredentials,
  getPreferences,
  setPreferences,
  getMyFunds,
  setMyFunds
};

export default authService;
