import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { register as signup, resetAuth } from '../features/auth/authSlice';
import Spinner from '../components/Spinner';

import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      tos: false
    }
  });
  const password = watch('password');

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      navigate('/dashboard');
    }

    return () => {
      dispatch(resetAuth());
    };
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onSubmit = data => dispatch(signup(data));

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={{ xs: 2, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <HowToRegIcon fontSize={isMobile ? 'medium' : 'large'} /> Sign Up
      </Typography>
      <Typography variant={'body2'} component={'p'} textAlign={'center'} mb={6} color={'text.secondary'}>
        Create an account and start AI-assited trading
      </Typography>

      <Box maxWidth={{ xs: '360px', sm: '600px' }} sx={{ width: '100%', margin: 'auto', typography: 'body2' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={{ xs: 3, sm: 4 }}>
            <TextField
              autoComplete='on'
              size='small'
              fullWidth
              label='Name'
              type='text'
              {...register('name', {
                required: {
                  value: true,
                  message: 'Enter your full name'
                }
              })}
              error={!!errors.name}
              helperText={errors.name?.message || 'Enter your full name'}
            />
            <TextField
              autoComplete='on'
              size='small'
              fullWidth
              label='Email'
              type='text'
              {...register('email', {
                required: {
                  value: true,
                  message: 'Email is required'
                },
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Invalid email format'
                }
              })}
              error={!!errors.email}
              helperText={errors.email?.message || 'Enter a valid email address'}
            />
            <TextField
              size='small'
              fullWidth
              label='Password'
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: {
                  value: true,
                  message: 'Password is required'
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              })}
              error={!!errors.password}
              helperText={errors.password?.message || 'Password must be 8 characters or more'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ '& .MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <KeyOffIcon /> : <KeyIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              size='small'
              fullWidth
              label='Confirm Password'
              type={showPassword ? 'text' : 'password'}
              {...register('confirmPassword', {
                required: {
                  value: true,
                  message: 'Confirn Password is required'
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                },
                validate: value => value === password || 'Passwords do not match'
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message || 'Passwrod and Confirm Password should match'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ '& .MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <KeyOffIcon /> : <KeyIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Controller
              name='tos'
              control={control}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    label={
                      <Typography variant={'body2'} textAlign='left'>
                        By signing up, you agree to our{' '}
                        <Link href='/legal/tos' target='_blank' rel='noreferrer' underline='none'>
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link href='/legal/privacy' target='_blank' underline='none'>
                          Privacy Policy
                        </Link>
                        .
                      </Typography>
                    }
                    control={
                      <Checkbox
                        size={'small'}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                        {...register('tos', {
                          required: 'Agreement is required'
                        })}
                      />
                    }
                  />
                  <Typography
                    component={'p'}
                    fontSize={'0.75rem'}
                    color={'error'}
                    textAlign={'left'}
                    style={{ marginTop: '0', marginLeft: '2.5rem' }}
                  >
                    {errors?.tos?.message}
                  </Typography>
                </>
              )}
            />

            <Button type='submit' variant='contained' color='primary' fullWidth disabled={!isDirty}>
              Sign Up
            </Button>

            <Stack
              width={'100%'}
              direction='row'
              justifyContent={'flex-end'}
              alignItems={'center'}
              style={{ marginTop: '1rem', marginBottom: '2rem' }}
            >
              <Typography variant='body2' display={'flex'} gap={1}>
                Already have an account?
                <Link
                  component={'button'}
                  variant='body2'
                  color='primary'
                  underline='none'
                  onClick={() => navigate('/login')}
                >
                  Log In
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </form>
        {/* </Box> */}
      </Box>
    </Container>
  );
};

export default Register;
