import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPreferences, setPreferences, resetAuth } from '../../features/auth/authSlice';
import { isEmptyObject, defaultPreferences, tooltipStyle } from '../../lib/utils';
import { toast } from 'react-toastify';
import { logout } from '../../features/auth/authSlice';
import { clearPortfolio } from '../../features/portfolio/portfolioSlice';

import {
  Box,
  Stack,
  Button,
  Typography,
  Divider,
  Chip,
  Slider,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Container,
  useMediaQuery
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const Preferences = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, preferences, isSuccess, isError } = useSelector(state => state.auth);
  const [preferenceData, setPreferenceData] = useState(preferences);
  const [isChanged, setIsChanged] = useState(false);

  const [profitRatio, setProfitRatio] = useState(Number(preferenceData.profitRatio) || 0);
  const [lossRatio, setLossRatio] = useState(Number(preferenceData.lossRatio) || 0);
  const [buyPeriod, setBuyPeriod] = useState(preferenceData.buyPeriod);
  const [buyPrice, setBuyPrice] = useState(preferenceData.buyPrice);
  const [buyQuantity, setBuyQuantity] = useState(preferenceData.buyQuantity);
  const [sellPeriod, setSellPeriod] = useState(preferenceData.sellPeriod);
  const [sellPrice, setSellPrice] = useState(preferenceData.sellPrice);
  const [sellQuantity, setSellQuantity] = useState(preferenceData.sellQuantity);
  const [premiumLogic, setPremiumLogic] = useState(preferenceData.premiumLogic);
  const [myFund, setMyFund] = useState(preferenceData.myFund || defaultPreferences.myFund);
  const [autoTrade, setAutoTrade] = useState(preferenceData.autoTrade);
  const [tradingPriority, setTradingPriority] = useState(preferenceData.tradingPriority);
  const [assetCount, setAssetCount] = useState(preferenceData.assetCount);
  const [tradeBudgetRatio, setTradeBudgetRatio] = useState(preferenceData.tradeBudgetRatio);
  const [tradingCountry, setTradingCountry] = useState(preferenceData.tradingCountry);
  const [emptyOutPortfolio, setEmptyOutPortfolio] = useState(preferenceData.emptyOutPortfolio);

  const isEgleoUser = token?.egleo;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isAutoTradeOn = autoTrade === 'on' ? true : false;

  useEffect(() => {
    if (token) {
      dispatch(getPreferences());
    }

    if (isEmptyObject(preferences)) {
      setPreferenceData(defaultPreferences);
    }

    return () => {
      dispatch(resetAuth());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess && isChanged) {
      setIsChanged(false);
      toast.success('Preferences saved!');
    }

    if (isError && isChanged) {
      setIsChanged(false);
      toast.error('Failed to save preferences!');
    }

    return () => {
      dispatch(resetAuth());
    };
  }, [isError, isSuccess, isChanged, dispatch]);

  const onChange = e => {
    const { name, value } = e.target;

    name === 'profitRatio' && setProfitRatio(value);
    name === 'lossRatio' && setLossRatio(value);
    name === 'buyPeriod' && setBuyPeriod(value);
    name === 'buyPrice' && setBuyPrice(value);
    name === 'buyQuantity' && setBuyQuantity(value);
    name === 'sellPeriod' && setSellPeriod(value);
    name === 'sellPrice' && setSellPrice(value);
    name === 'sellQuantity' && setSellQuantity(value);
    name === 'premiumLogic' && setPremiumLogic(value);
    name === 'myFund' && setMyFund(value);
    name === 'autoTrade' && setAutoTrade(value);
    name === 'tradingPriority' && setTradingPriority(value);
    name === 'assetCount' && setAssetCount(value);
    name === 'tradeBudgetRatio' && setTradeBudgetRatio(value);
    name === 'tradingCountry' && setTradingCountry(value);
    name === 'emptyOutPortfolio' && setEmptyOutPortfolio(value);

    setIsChanged(true);
    setPreferenceData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();
    const newPreferences = { ...defaultPreferences, ...preferenceData };

    dispatch(setPreferences(newPreferences));
  };

  const onLogout = () => {
    dispatch(logout(token._id));
    dispatch(resetAuth());
    dispatch(clearPortfolio());
    navigate('/');
  };

  if (isError) {
    // toast.error(message);
    onLogout();
  }

  const ratioMarks = [
    {
      value: 5,
      label: '5%'
    },
    {
      value: 10,
      label: '10%'
    },
    {
      value: 25,
      label: '25%'
    },
    {
      value: 50,
      label: '50%'
    },
    {
      value: 75,
      label: '75%'
    },
    {
      value: 100,
      label: '100%'
    }
  ];

  const sliderStyle = {
    height: 6,

    '& .MuiSlider-track': {
      border: 'none'
    },
    '& .MuiSlider-thumb': {
      height: 16,
      width: 16,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit'
      },
      '&:before': {
        display: 'none'
      }
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 24,
      height: 24,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#3682D6',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
      },
      '& > *': {
        transform: 'rotate(45deg)'
      }
    }
  };

  const premiumLogicList = [
    {
      name: 'Socrates',
      tooltip: 'Best assets to buy with high profit in long term, by Socrates.'
    },
    {
      name: 'Egleo',
      tooltip: 'Best assets to buy with high profit in short term, by Egleo.'
    },
    {
      name: 'Plato',
      tooltip: 'Best assets to buy at the lowest price, by Plato.'
    }
    // {
    //   name: 'Aristotle',
    //   tooltip: 'Pump and dump stocks with high risk and high return, by Aristotle.'
    // },
    // {
    //   name: 'Pythagoras',
    //   tooltip: 'Best assets expected to gain high profit for day traders, by Pythagoras.'
    // },
    // {
    //   name: 'Parmenides',
    //   tooltip: 'Best assets for short term trading, by Parmenides.'
    // }
  ];

  const myFundList = [
    {
      name: 'PRIMARY',
      tooltip: 'My PRIMARY fund'
    },
    {
      name: 'SECONDARY',
      tooltip: 'My SECONDARY fund'
    }
  ];

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={{ xs: 2, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <SettingsIcon fontSize={isMobile ? 'medium' : 'large'} /> Trading Preferences
      </Typography>
      <Typography variant={'body2'} component={'p'} textAlign={'center'} mb={6} color={'text.secondary'}>
        Set your trading preferences, such as priorities and customized logics.
        <br />
        All your trading transactions will be performed following the preferences set below.
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '800px', lg: '900px' }}
        sx={{ width: '100%', margin: 'auto', typography: 'body2' }}
      >
        <Divider textAlign='center' sx={{ margin: '2rem 0', padding: { sm: '0 1rem' } }}>
          <Chip label='Auto Trading' sx={{ fontSize: '14px', padding: '0 0.5rem', background: '#1e4976' }} />
        </Divider>

        <Stack
          gap={1}
          mx={{ sm: 2 }}
          p={2}
          border='1px solid rgba(255, 255, 255, 0.12)'
          borderRadius='6px'
          fontSize={'14px'}
        >
          <Stack
            display={'grid'}
            gridTemplateColumns={{ xs: '40% 60%' }}
            alignItems={'center'}
            gap={1}
            px={{ xs: 0, sm: 3 }}
            my={2}
          >
            <Typography variant='body2'>Auto Trading</Typography>
            <ToggleButtonGroup
              size='small'
              color='primary'
              value={autoTrade}
              exclusive
              onChange={onChange}
              aria-label='Auto Trading'
            >
              <Tooltip
                disableFocusListener
                arrow
                title='Disable auto trading'
                PopperProps={{ sx: tooltipStyle }}
                value='off'
              >
                <ToggleButton name='autoTrade' value='off'>
                  Off
                </ToggleButton>
              </Tooltip>
              <Tooltip
                disableFocusListener
                arrow
                title='Enable auto trading'
                PopperProps={{ sx: tooltipStyle }}
                value='on'
              >
                <ToggleButton name='autoTrade' value='on'>
                  On
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Stack>
        </Stack>

        {isAutoTradeOn ? (
          <>
            <Divider textAlign='center' sx={{ margin: '3rem 0 2rem', padding: { sm: '0 1rem' } }}>
              <Chip
                label='Auto Trading Preferences'
                sx={{ fontSize: '14px', padding: '0 0.5rem', background: '#1e4976' }}
              />
            </Divider>

            <Stack
              gap={1}
              mx={{ sm: 2 }}
              p={2}
              border='1px solid rgba(255, 255, 255, 0.12)'
              borderRadius='6px'
              fontSize={'14px'}
            >
              <Stack
                display={'grid'}
                gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                alignItems={'center'}
                gap={1}
                px={{ xs: 0, sm: 3 }}
                my={1.5}
              >
                <Typography variant='body2'>Auto Trading Logic</Typography>
                <ToggleButtonGroup
                  size='small'
                  color='primary'
                  value={tradingPriority}
                  exclusive
                  onChange={onChange}
                  aria-label='Trading Priority'
                  sx={{ overflowWrap: 'anywhere' }}
                >
                  <Tooltip
                    disableFocusListener
                    arrow
                    title='Auto trading will be performed upon the seleted Premium Logic.'
                    PopperProps={{ sx: tooltipStyle }}
                    value='premiumLogic'
                  >
                    <ToggleButton name='tradingPriority' value='premiumLogic'>
                      Premium
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip
                    disableFocusListener
                    arrow
                    title='Auto trading will be performed upon the selected My Fund trading signals.'
                    PopperProps={{ sx: tooltipStyle }}
                    value='myfund'
                  >
                    <ToggleButton name='tradingPriority' value='myFund'>
                      My Fund
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Stack>

              <Stack
                display={tradingPriority !== 'myFund' ? 'grid' : 'none'}
                gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                alignItems={'center'}
                gap={1}
                px={{ xs: 0, sm: 3 }}
                my={1.5}
              >
                <Typography variant='body2'>Number of Assets</Typography>
                <Tooltip
                  disableFocusListener
                  arrow
                  title='Select a number of assets to trade with auto trading.'
                  PopperProps={{ sx: tooltipStyle }}
                >
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={assetCount}
                    exclusive
                    onChange={onChange}
                    aria-label='Number of Assets to Trade'
                  >
                    <ToggleButton name='assetCount' value='5'>
                      5
                    </ToggleButton>
                    <ToggleButton name='assetCount' value='10'>
                      10
                    </ToggleButton>
                    <ToggleButton name='assetCount' value='20'>
                      20
                    </ToggleButton>
                    <ToggleButton name='assetCount' value='30'>
                      30
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
              </Stack>

              <Stack
                display={'grid'}
                gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                alignItems={'center'}
                gap={1}
                px={{ xs: 0, sm: 3 }}
                my={1.5}
              >
                <Typography variant='body2'>Trading Budget</Typography>
                <Tooltip
                  disableFocusListener
                  arrow
                  title='Select a budget for auto trading (% of your account cash balance).'
                  PopperProps={{ sx: tooltipStyle }}
                >
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={tradeBudgetRatio}
                    exclusive
                    onChange={onChange}
                    aria-label='Trading Budget'
                  >
                    <ToggleButton name='tradeBudgetRatio' value='25'>
                      25%
                    </ToggleButton>
                    <ToggleButton name='tradeBudgetRatio' value='50'>
                      50%
                    </ToggleButton>
                    <ToggleButton name='tradeBudgetRatio' value='75'>
                      75%
                    </ToggleButton>
                    <ToggleButton name='tradeBudgetRatio' value='100'>
                      100%
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
              </Stack>

              <Stack
                display={tradingPriority !== 'myFund' ? 'grid' : 'none'}
                gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                alignItems={'center'}
                gap={1}
                px={{ xs: 0, sm: 3 }}
                my={1.5}
              >
                <Typography variant='body2'>Trading Country</Typography>
                <Tooltip
                  disableFocusListener
                  arrow
                  title='Select a country to trade with.'
                  PopperProps={{ sx: tooltipStyle }}
                >
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={tradingCountry}
                    exclusive
                    onChange={onChange}
                    aria-label='Trading Country'
                  >
                    <ToggleButton name='tradingCountry' value='KOREA'>
                      KOREA
                    </ToggleButton>
                    <ToggleButton name='tradingCountry' value='USA'>
                      USA
                    </ToggleButton>
                    <ToggleButton name='tradingCountry' value='ALL'>
                      ALL
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
              </Stack>

              <Stack
                display={tradingPriority === 'profitLoss' ? 'grid' : 'none'}
                gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                alignItems={'center'}
                gap={1}
                px={{ xs: 0, sm: 3 }}
                my={1.5}
              >
                <Typography variant='body2'>Clear Portfolio</Typography>
                <ToggleButtonGroup
                  size='small'
                  color='primary'
                  value={emptyOutPortfolio}
                  exclusive
                  onChange={onChange}
                  aria-label='Clear Portfolio'
                >
                  <Tooltip
                    disableFocusListener
                    arrow
                    title='Keep all assets in the portfolio after market close.'
                    PopperProps={{ sx: tooltipStyle }}
                    value='no'
                  >
                    <ToggleButton name='emptyOutPortfolio' value='no'>
                      Off
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip
                    disableFocusListener
                    arrow
                    title='Sell all assets in the portfolio before market close.'
                    PopperProps={{ sx: tooltipStyle }}
                    value='yes'
                  >
                    <ToggleButton name='emptyOutPortfolio' value='yes'>
                      On
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            {tradingPriority === 'premiumLogic' && (
              <>
                <Divider textAlign='center' sx={{ margin: '3rem 0 2rem', padding: { sm: '0 1rem' } }}>
                  <Chip label='Premium Logic' sx={{ fontSize: '14px', padding: '0 0.5rem', background: '#1e4976' }} />
                </Divider>

                <Stack m={{ sm: 2 }}>
                  <Box
                    component={'fieldset'}
                    display={'grid'}
                    border='1px solid rgba(255, 255, 255, 0.12)'
                    borderRadius='6px'
                    fontSize={'14px'}
                    p={3}
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby='Premium Logic'
                        name='premiumLogic'
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around'
                        }}
                        value={premiumLogic}
                        onChange={onChange}
                      >
                        {premiumLogicList.map(logic => (
                          <Tooltip
                            key={logic.name}
                            disableFocusListener
                            arrow
                            title={logic.tooltip}
                            PopperProps={{ sx: tooltipStyle }}
                            value='once'
                          >
                            <FormControlLabel
                              label={logic.name}
                              value={logic.name.toLowerCase()}
                              control={<Radio size='small' />}
                              disabled={!isEgleoUser && logic.name !== 'Socrates'}
                            />
                          </Tooltip>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Stack>
              </>
            )}

            {tradingPriority === 'myFund' && (
              <>
                <Divider textAlign='center' sx={{ margin: '3rem 0 2rem', padding: { sm: '0 1rem' } }}>
                  <Chip label='My Fund' sx={{ fontSize: '14px', padding: '0 0.5rem', background: '#1e4976' }} />
                </Divider>

                <Stack m={{ sm: 2 }}>
                  <Box
                    component={'fieldset'}
                    display={'grid'}
                    border='1px solid rgba(255, 255, 255, 0.12)'
                    borderRadius='6px'
                    fontSize={'14px'}
                    p={3}
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby='My Fund'
                        name='myFund'
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around'
                        }}
                        value={myFund}
                        onChange={onChange}
                      >
                        {myFundList.map(logic => (
                          <Tooltip
                            key={logic.name}
                            disableFocusListener
                            arrow
                            title={logic.tooltip}
                            PopperProps={{ sx: tooltipStyle }}
                            value='once'
                          >
                            <FormControlLabel label={logic.name} value={logic.name} control={<Radio size='small' />} />
                          </Tooltip>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Stack>
              </>
            )}

            <Divider textAlign='center' sx={{ margin: '3rem 0 2rem', padding: { sm: '0 1rem' } }}>
              <Chip
                label='Profit / Loss Taking Preferences'
                sx={{ fontSize: '14px', padding: '0 0.5rem', background: '#1e4976' }}
              />
            </Divider>

            <Stack m={{ sm: 2 }} gap={4}>
              <Box
                component={'fieldset'}
                border='1px solid rgba(255, 255, 255, 0.12)'
                borderRadius='6px'
                fontSize={'14px'}
                p={4}
                pt={8}
              >
                <legend style={{ textAlign: 'left', marginLeft: '2rem' }}>Take Profit Ratio</legend>
                <Slider
                  valueLabelDisplay='on'
                  aria-label='EgLeo slider'
                  name='profitRatio'
                  defaultValue={profitRatio}
                  value={profitRatio}
                  marks={ratioMarks}
                  onChange={onChange}
                  sx={sliderStyle}
                />
              </Box>

              <Box
                component={'fieldset'}
                border='1px solid rgba(255, 255, 255, 0.12)'
                borderRadius='6px'
                fontSize={'14px'}
                p={4}
                pt={8}
              >
                <legend style={{ textAlign: 'left', marginLeft: '2rem' }}>Stop Loss Ratio</legend>
                <Slider
                  valueLabelDisplay='on'
                  aria-label='EgLeo slider'
                  name='lossRatio'
                  defaultValue={lossRatio}
                  value={lossRatio}
                  marks={ratioMarks}
                  onChange={onChange}
                  sx={sliderStyle}
                />
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Divider textAlign='center' sx={{ margin: '3rem 0 2rem', padding: { sm: '0 1rem' } }}>
              <Chip
                label='Manual Order Preferences'
                sx={{ fontSize: '14px', padding: '0 0.5rem', background: '#1e4976' }}
              />
            </Divider>

            <Stack m={{ sm: 2 }} gap={4}>
              <Box
                component={'fieldset'}
                display={'grid'}
                border='1px solid rgba(255, 255, 255, 0.12)'
                borderRadius='6px'
                fontSize={'14px'}
                p={2}
              >
                <legend style={{ textAlign: 'left', marginLeft: '2rem' }}>Buy Order</legend>
                <Stack
                  display={'grid'}
                  gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                  alignItems={'center'}
                  px={{ xs: 0, sm: 3 }}
                  my={1.5}
                >
                  <Typography variant='body2'>Buy Spread</Typography>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={buyPeriod}
                    exclusive
                    onChange={onChange}
                    aria-label='Buy Spread'
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Buy all shares of an asset immediately.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='once'
                    >
                      <ToggleButton name='buyPeriod' value='once'>
                        Off
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Buy all shares of an asset spread over 7 times a day.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='spread'
                    >
                      <ToggleButton name='buyPeriod' value='spread'>
                        On
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
                <Stack
                  display={'grid'}
                  gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                  alignItems={'center'}
                  px={{ xs: 0, sm: 3 }}
                  my={1.5}
                >
                  <Typography variant='body2'>Buy Price</Typography>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={buyPrice}
                    exclusive
                    onChange={onChange}
                    aria-label='Buy Price'
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Buy an asset immediately at the current market price.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='market'
                    >
                      <ToggleButton name='buyPrice' value='market'>
                        Market
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Buy an asset at a specific price when placing a buy order.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='limit'
                    >
                      <ToggleButton name='buyPrice' value='limit'>
                        Limit
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
                <Stack
                  display={'grid'}
                  gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                  alignItems={'center'}
                  px={{ xs: 0, sm: 3 }}
                  my={1.5}
                >
                  <Typography variant='body2'>Buy Quantity</Typography>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={buyQuantity}
                    exclusive
                    onChange={onChange}
                    aria-label='Buy Quantity'
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='5% of your cash balance will be allocated to calculate the number of shares to buy.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='default'
                    >
                      <ToggleButton name='buyQuantity' value='default'>
                        Auto
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Set a specific number of shares when placing a buy order.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='custom'
                    >
                      <ToggleButton name='buyQuantity' value='custom'>
                        Manual
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
              </Box>

              <Box
                component={'fieldset'}
                display={'grid'}
                border='1px solid rgba(255, 255, 255, 0.12)'
                borderRadius='6px'
                fontSize={'14px'}
                p={2}
              >
                <legend style={{ textAlign: 'left', marginLeft: '2rem' }}>Sell Order</legend>
                <Stack
                  display={'grid'}
                  gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                  alignItems={'center'}
                  px={{ xs: 0, sm: 3 }}
                  my={1.5}
                >
                  <Typography variant='body2'>Sell Spread</Typography>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={sellPeriod}
                    exclusive
                    onChange={onChange}
                    aria-label='Sell Spread'
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Sell all shares of an asset immediately.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='once'
                    >
                      <ToggleButton name='sellPeriod' value='once'>
                        Off
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Sell all shares of an asset spread over 7 times a day.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='spread'
                    >
                      <ToggleButton name='sellPeriod' value='spread'>
                        On
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
                <Stack
                  display={'grid'}
                  gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                  alignItems={'center'}
                  px={{ xs: 0, sm: 3 }}
                  my={1.5}
                >
                  <Typography variant='body2'>Sell Price</Typography>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={sellPrice}
                    exclusive
                    onChange={onChange}
                    aria-label='Buy Price'
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Sell an asset immediately at the current market price.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='market'
                    >
                      <ToggleButton name='sellPrice' value='market'>
                        Market
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Sell an asset at a specific price when placing a sell order.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='limit'
                    >
                      <ToggleButton name='sellPrice' value='limit'>
                        Limit
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
                <Stack
                  display={'grid'}
                  gridTemplateColumns={{ xs: '30% 70%', md: '40% 60%' }}
                  alignItems={'center'}
                  px={{ xs: 0, sm: 3 }}
                  my={1.5}
                >
                  <Typography variant='body2'>Sell Quantity</Typography>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    value={sellQuantity}
                    exclusive
                    onChange={onChange}
                    aria-label='Sell Quantity'
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Sell all shares immediately.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='default'
                    >
                      <ToggleButton name='sellQuantity' value='default'>
                        Auto
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Set a specific number of shares when placing a sell order.'
                      PopperProps={{ sx: tooltipStyle }}
                      value='custom'
                    >
                      <ToggleButton name='sellQuantity' value='custom'>
                        Manual
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
              </Box>
            </Stack>
          </>
        )}

        <Stack mt={4} p={{ xs: 0, sm: 2 }} display='flex' justifyContent='center'>
          <Button variant='contained' disabled={!isChanged} onClick={onSubmit}>
            Save
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default Preferences;
