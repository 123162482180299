import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const { token } = useSelector(state => state.auth);

  if (token?.token) {
    const decoded = JSON.parse(atob(token?.token.split('.')[1]));
    const isTokenExpired = decoded.exp * 1000 < Date.now() ? true : false;

    return !isTokenExpired ? <Outlet /> : <Navigate to='/login' replace />;
  } else {
    return <Navigate to='/login' replace />;
  }
};

export default PrivateRoute;
