import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      // main: '#90caf9'
      main: '#00AEEF'
    },
    secondary: {
      main: '#ce93d8'
    },
    background: {
      // default: '#0e1010',
      // paper: '#0e1010',
      // appbar: '#000a14',
      // sidebar: '#040c14'
      default: 'rgba(2, 11, 36)',
      paper: 'rgba(2, 11, 36)',
      appbar: 'rgb(8, 18, 52)',
      sidebar: 'linear-gradient(rgba(8, 18, 52) 0%, rgba(2, 11, 36) 100%)'
    }
  },
  props: {
    MuiTooltip: {
      arrow: true
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('sm')]: {
            padding: 0
          }
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.75
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input': {
            fontSize: '14px'
          },
          '& input::label': {
            fontSize: '14px'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          width: '100%',
          borderColor: '#1e4976',
          fontSize: '12px',

          '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: '#132f4c',
            borderColor: 'rgb(0, 89, 178) !important'
          }
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: '24px 0'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
          boxShadow: 'none'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          '&:hover': {
            backgroundColor: 'rgba(19, 47, 76, 0.75) !important'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // borderRadius: 24,
          '&:hover': {
            backgroundColor: 'rgba(19, 47, 76, 0.75) !important'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '28px'
        }
      }
    }
  }
});
