import axios from 'axios';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetAuth } from '../../features/auth/authSlice';
import { isDomestic, tooltipStyle, sleep } from '../../lib/utils';

import BuyAsset from './BuyAsset';
import AddFund from '../myfund/AddFund';

import { Link, Tooltip, useMediaQuery, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const BuySignalList = ({ country }) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [assets, setAssets] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: page,
    pageSize: 10
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'marketcap',
      sort: 'desc'
    }
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const { token, credentials, preferences } = useSelector(state => state.auth);

  const { appkey, appsecret } = credentials;
  const { tradingMode: env } = preferences;
  const isMobile = useMediaQuery('(max-width: 600px)');

  // function newAbortSignal(timeoutMs) {
  //   const abortController = new AbortController();
  //   setTimeout(() => abortController.abort(), timeoutMs || 0);

  //   return abortController.signal;
  // }

  const getCurrentPrice = async (country, ticker, marketCode) => {
    const API_URL = `/api/${env.toLowerCase()}/assets/price/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        },
        params: {
          userId: token._id,
          appkey,
          appsecret,
          country,
          marketCode
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return isDomestic(country) ? response.data.output.stck_prpr : response.data.output.last;
    } catch (error) {
      console.log(error.response.data);
      return 0;
    }
  };

  const getBuySignalAssets = useCallback(async () => {
    const API_URL = '/api/lists/';

    // set request query params
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`
      },
      params: {
        country
      }
    };

    const response = await axios.get(API_URL + 'buysignal', config);

    return response.data;
  }, [token, country]);

  const prepareAssetData = useCallback(async (assets, page) => {
    setIsLoading(true);

    const buyAssets = [];
    const pageAssets = assets.slice(page * paginationModel.pageSize, (page + 1) * paginationModel.pageSize);

    for (const [idx, asset] of pageAssets.entries()) {
      await sleep(env === 'SANDBOX' ? 500 : 100);

      let assetPrice = 0;

      try {
        assetPrice = await getCurrentPrice(country, asset.shcode, asset.marketcode);
      } catch (error) {
        console.log(`idx: ${idx}, asset: ${asset.name}, error: ${error.response.data.msg1}`);
      }

      const data = {
        id: idx,
        assetName: asset.hname,
        ticker: asset.shcode,
        assetPrice,
        marketCap: asset.marketcap,
        country: country,
        marketCode: country === 'KOREA' ? null : asset.marketcode
      };

      buyAssets.push(data);
    }

    setRows(buyAssets);
    setIsLoading(false);
  }, []);

  const CustomNoRowsOverlay = () => (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      No assets.
    </Box>
  );

  const renderAssetName = params => {
    return isDomestic(params.row.country) ? (
      <Tooltip disableFocusListener arrow title={params.row.ticker} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.naver.com/item/main.naver?code=${params.row.ticker}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.value}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip disableFocusListener arrow title={params.value} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.yahoo.com/quote/${params.row.ticker}?p=${params.row.ticker}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.row.ticker}
        </Link>
      </Tooltip>
    );
  };

  const renderBuyButton = params => {
    const assetData = {
      shcode: params.row.ticker,
      hname: params.row.assetName,
      marketcode: params.row.marketCode || null
    };
    const country = params.row.country;

    return params.row.tradeSignal !== 99 && <BuyAsset asset={assetData} country={country} />;
  };

  const renderAddButton = params => {
    const { assetName: name, ticker, assetPrice: price, marketCode } = params.row;
    const asset = {
      name,
      ticker,
      price,
      marketCode
    };

    return <AddFund asset={asset} />;
  };

  const columns = [
    {
      field: 'assetName',
      headerName: 'Asset',
      headerAlign: 'center',
      align: 'center',
      flex: isMobile ? 0.5 : 0.6,
      // sortable: false,
      renderCell: renderAssetName
    },
    {
      field: 'assetPrice',
      headerName: 'Price',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      sortable: false,
      valueFormatter: value =>
        country === 'KOREA'
          ? Number(value).toLocaleString('en-us')
          : `$${Number(value).toFixed(2).toLocaleString('en-us')}`
    },
    {
      field: 'marketCap',
      headerName: 'Market Cap',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: isMobile ? 0.3 : 0.4,
      valueFormatter: value =>
        country === 'KOREA'
          ? Math.floor(value / 10).toLocaleString('en-us') + ' B'
          : '$' + Math.floor(value / 10).toLocaleString('en-us') + ' B'
    },
    {
      field: 'buyBtn',
      headerName: 'Trade',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.2,
      sortable: false,
      renderCell: renderBuyButton
    },
    {
      field: 'addBtn',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.1,
      sortable: false,
      renderCell: renderAddButton
    }
  ];

  const handlePageClick = useCallback(async (params, assets) => {
    setPaginationModel({ ...paginationModel, page: params.page });
    setPage(params.page);

    await prepareAssetData(assets, params.page);
  }, []);

  const handleSortChange = async newSortModel => {
    setSortModel(newSortModel);

    if (newSortModel.length === 0) return;

    const { field: sortKey, sort: sortOrder } = newSortModel[0];

    if (sortKey === 'assetName') {
      assets.sort((a, b) => (sortOrder === 'asc' ? a.hname.localeCompare(b.hname) : b.hname.localeCompare(a.hname)));
    } else if (sortKey === 'marketCap') {
      assets.sort((a, b) => (sortOrder === 'asc' ? a.marketcap - b.marketcap : b.marketcap - a.marketcap));
    }

    await prepareAssetData(assets, 0);

    setPaginationModel({ ...paginationModel, page: 0 });
    setPage(0);
  };

  useEffect(() => {
    const prep = async () => {
      const buyAssets = await getBuySignalAssets();
      setAssets(buyAssets);
      setRowCount(buyAssets.length);
      await prepareAssetData(buyAssets, 0);
    };

    prep();

    return () => {
      dispatch(resetAuth());
    };
  }, []);

  return (
    <Box width={'100%'}>
      <DataGrid
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={paginationModel => handlePageClick(paginationModel, assets)}
        sortModel={sortModel}
        onSortModelChange={newSortModel => handleSortChange(newSortModel)}
        sortingOrder={['asc', 'desc']}
        dense
        autoHeight
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        loading={isLoading}
        disableRowSelectionOnClick
        disableColumnMenu
        columnVisibilityModel={{
          assetName: true,
          assetPrice: !isMobile ? true : false,
          marketCap: true,
          buyBtn: true,
          addBtn: true
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true
          }
        }}
        pageSizeOptions={[10]}
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
          '& .MuiDataGrid-columnHeader': { backgroundColor: '#001021' }
        }}
      />
    </Box>
  );
};

export default BuySignalList;
