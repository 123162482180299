import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmptyObject, isMarketOpen, COUNTRY } from '../../lib/utils';
import TradeLogList from './TradeLogList';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Tab,
  Typography,
  useMediaQuery
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HistoryIcon from '@mui/icons-material/History';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const TradeLog = () => {
  const { credentials } = useSelector(state => state.auth);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [open, setOpen] = useState(true);
  const [tabIndex, setTabIndex] = useState(isMarketOpen(COUNTRY.USA) ? 'usa' : 'korea');

  const handleClose = () => setOpen(false);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <HistoryIcon fontSize={isMobile ? 'medium' : 'large'} /> Trade Log
      </Typography>

      {isEmptyObject(credentials) ? (
        <Dialog
          maxWidth={'xs'}
          PaperProps={{ sx: { width: '400px' } }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
              }
            }
          }}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle textAlign={'center'}>Invalid KIS Account Credentials</DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: '1rem auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography fontSize={14}>
              Please check your KIS account credentials to access your porfolio and place any trading orders.
            </Typography>
            <Link to={{ pathname: '/profile', hash: 'credentials' }}>
              <Button sx={{ marginTop: 4, marginBottom: 2 }} variant='outlined'>
                Check Credentials <ArrowForwardIcon sx={{ marginLeft: '0.5rem' }} />
              </Button>
            </Link>
          </DialogContent>
          <DialogActions>
            <Link to={{ pathname: '/profile', hash: 'credentials' }}>
              <Button onClick={handleClose}>Go</Button>
            </Link>
          </DialogActions>
        </Dialog>
      ) : (
        <Box
          maxWidth={{ xs: '360px', sm: '600px', md: '800px', lg: '1000px' }}
          sx={{ width: '100%', margin: 'auto', typography: 'body1' }}
        >
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(e, value) => setTabIndex(value)}
                aria-label='TradeLog Tabs'
                variant={isMobile ? 'scrollable' : 'standard'}
                scrollButtons={isMobile ? true : false}
                allowScrollButtonsMobile={isMobile ? true : false}
              >
                <Tab label='Korea' value='korea' />
                <Tab label='USA' value='usa' />
              </TabList>
            </Box>
            <TabPanel value='korea'>
              <TradeLogList country={COUNTRY.KOREA} />
            </TabPanel>
            <TabPanel value='usa'>
              <TradeLogList country={COUNTRY.USA} />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </Container>
  );
};

export default TradeLog;
