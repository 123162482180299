import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  isEmptyObject,
  getCurrentDate,
  isMarketOpen,
  isDomestic,
  TICKCOLOR_UP,
  TICKCOLOR_DOWN,
  TICKCOLOR_NEUTRAL
} from '../../lib/utils';
import { toast } from 'react-toastify';

import {
  Box,
  Stack,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BuyAsset = ({ asset, country }) => {
  const { token, credentials, preferences } = useSelector(state => state.auth);
  const env = preferences.tradingMode;
  const { accountBalance } = useSelector(state => state.portfolio);
  const { buyPeriod, buyPrice, buyQuantity, assets } = preferences;
  const { appkey, appsecret, accountNumber, accountCode } = credentials;

  const getAssetType = async ticker => {
    const API_URL = `/api/${env.toLowerCase()}/assets/type/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return response.data[0].type;
    } catch (error) {
      console.log(error.response.data);
      return 0;
    }
  };

  const getAssetPrice = async () => {
    try {
      const API_URL = `/api/${env.toLowerCase()}/assets/price/`;
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        },
        params: {
          userId: token._id,
          appkey,
          appsecret,
          country,
          marketCode: asset.marketcode || ''
        }
      };

      // const ticker = isDomestic(country) && asset.type === 1 ? `Q${asset.shcode}` : asset.shcode;

      if (isDomestic(country)) {
        const assetType = await getAssetType(asset.shcode);

        if (assetType === 3) {
          asset.shcode = 'Q' + asset.shcode;
        }
      }

      // console.log(asset);

      const response = await axios.get(API_URL + asset.shcode, config);

      return isDomestic(country)
        ? {
            assetPrice: response.data.output.stck_prpr,
            diff: response.data.output.prdy_vrss,
            ratio: response.data.output.prdy_ctrt
          }
        : {
            assetPrice: response.data.output.last,
            diff: response.data.output.diff,
            ratio: response.data.output.rate
          };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getAssetBuyPreference = assetId => {
    if (assets) {
      const found = Object.entries(assets).map(([key, value]) => {
        if (key === assetId && value.buyOneTime === true) return true;
        else return false;
      });

      return found.includes(true);
    }

    return false;
  };

  const createOrderSchedule = (spreadOver, quantity, scheduledDate, orderSchedules) => {
    let qty = 1;
    let diff = 0;
    let remainder = 0;
    const divisor = spreadOver === '1d' ? scheduledDate.length : spreadOver === '5d' ? 5 : 4;

    if (quantity < divisor) {
      diff = divisor - quantity;
    } else {
      qty = Math.floor(quantity / divisor);
      remainder = quantity % divisor;
    }

    for (let i = 0; i < diff; i++) {
      scheduledDate.pop();
    }

    scheduledDate.forEach((date, index) => {
      const schedule = {
        tradeDate: date,
        tradeItem: [
          {
            tradeType: 'buy',
            ticker: asset.shcode,
            assetName: asset.hname,
            quantity: index < remainder ? qty + 1 : qty,
            price: '0',
            country
          }
        ]
      };

      orderSchedules.schedules.push(schedule);
    });
  };

  const onClick = path => (window.location.href = `${path}#credentials`);

  const onPurchase = async (asset, assetPrice) => {
    let quantity = orderQuantity;
    let price = orderPrice;
    let isMarketOrder = true;
    const orderSchedules = {
      userId: token._id,
      // userEmail: token.email,
      tradingMode: env,
      credentials: {
        appkey,
        appsecret,
        accountNumber,
        accountCode
      },
      schedules: []
    };

    // set the price
    switch (buyPrice) {
      case 'limit':
        if (!price) {
          toast.error('Price is missing!');
          return;
        }

        if (isNaN(price)) {
          toast.error('Price must be a number!');
          return;
        }

        if (price <= 0) {
          toast.error('Price must be greather than zero!');
          return;
        }

        isMarketOrder = false;

        break;
      case 'market':
      default:
        price = isDomestic(country) ? price : assetPrice;
        break;
    }

    // set the quantity
    switch (buyQuantity) {
      case 'custom':
        const customPrice = Number(price) !== 0 ? Number(price) : Number(assetPrice);

        if (!quantity) {
          toast.error('Quantity is missing!');
          return;
        }

        if (isNaN(quantity)) {
          toast.error('Quantity must be a number!');
          return;
        }

        if (quantity <= 0) {
          toast.error('Quantity must be greather than zero!');
          return;
        }

        if (customPrice * quantity > accountBalance) {
          toast.error(
            <p>
              Not enough balance!
              <br />
              Please adjust the quantity.
            </p>,
            { autoClose: false }
          );
          return;
        }

        break;
      case 'default':
      default:
        const budget = Math.floor(Number(accountBalance) * 0.05);
        price = Number(price) !== 0 ? Number(price) * 1.01 : Number(assetPrice) * 1.01;
        quantity = isDomestic(country) ? Math.floor(budget / price) : Math.floor(budget / (price * 1310));

        console.log(`accountBalance: ${accountBalance}, price: ${price}, quantity: ${quantity}, budget: ${budget}`);

        break;
    }

    // set the spread over
    switch (buyPeriod) {
      case 'spread':
        if (spreadOver === '') {
          toast.error('Spread Over option is missing!');
          return;
        }

        const { dateKR } = getCurrentDate();
        const hour = dateKR.hours();
        const minute = dateKR.minutes();
        let day = dateKR.day();

        const tradeDate = dateKR.clone();
        const scheduledDate = [];

        switch (spreadOver) {
          case '1d':
            tradeDate.minutes(10).seconds(0);

            for (let i = 9; i < 16; i++) {
              if (hour < i || (hour >= 9 && hour <= i)) {
                tradeDate.hours(i);
                scheduledDate.push(tradeDate.format());
              }

              if (hour === i && minute >= 10) continue;
            }

            break;
          // buy an asset at 9:10am, everyday for the next 5 business days
          case '5d':
            tradeDate.hours(9).minutes(10).seconds(0);

            for (let i = 0; i < 5; i++) {
              if (isMarketOpen(country) && i === 0) {
                // buy the asset right now
                console.log('buy asset now - first day');
              } else {
                if (day === 0 || (day > 0 && day < 5)) {
                  tradeDate.add(1, 'days');
                } else if (day === 5) {
                  tradeDate.add(3, 'days');
                } else if (day === 6) {
                  tradeDate.add(2, 'days');
                }

                day = (day + i) % 6;

                scheduledDate.push(tradeDate.format());
              }
            }

            break;
          case '20d':
          default:
            tradeDate.hours(9).minutes(10).seconds(0);

            for (let i = 0; i < 4; i++) {
              if (isMarketOpen(country) && i === 0) {
                // buy the asset right now
                console.log('buy asset now - first day');
              } else {
                if (day > 0 && day < 5) {
                  tradeDate.add(7, 'days');
                }

                scheduledDate.push(tradeDate.format());
              }
            }

            break;
        }

        createOrderSchedule(spreadOver, quantity, scheduledDate, orderSchedules);

        const API_URL = `/api/${env.toLowerCase()}/trades/schedule`;

        const response = await axios.post(API_URL, orderSchedules);

        if (response) {
          // console.log(response.data.schedules);
        }

        break;
      case 'once':
      default:
        break;
    }

    const API_URL = !isDomestic(country)
      ? `/api/${env.toLowerCase()}/trades/order/buy/limit`
      : isMarketOrder
      ? `/api/${env.toLowerCase()}/trades/order/buy/market`
      : `/api/${env.toLowerCase()}/trades/order/buy/limit`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`
      }
    };

    // set request body data
    const data = !isDomestic(country)
      ? {
          userId: token._id,
          tradeBy: 'user',
          appkey,
          appsecret,
          accountNumber,
          accountCode,
          ticker: asset.shcode,
          assetName: asset.hname,
          quantity: quantity.toString(),
          price: price.toString(),
          marketCode: asset.marketcode.split(' ')[0],
          country
        }
      : isMarketOrder
      ? {
          userId: token._id,
          tradeBy: 'user',
          appkey,
          appsecret,
          accountNumber,
          accountCode,
          ticker: asset.shcode,
          assetName: asset.hname,
          quantity: quantity.toString(),
          country
        }
      : {
          userId: token._id,
          tradeBy: 'user',
          appkey,
          appsecret,
          accountNumber,
          accountCode,
          ticker: asset.shcode,
          assetName: asset.hname,
          quantity: quantity.toString(),
          price: price.toString(),
          country
        };

    try {
      const response = await axios.post(API_URL, data, config);

      if (response.data.rt_cd === '0') {
        console.log(
          `Buy order placed :: asset=${asset.hname}, ticker=${asset.shcode}, quantity=${quantity}, price=${price}`
        );

        toast.success('Buy order placed successfully!');
      } else {
        console.log(
          `Failed to place a buy order: asset=${asset.hname}, ticker=${asset.shcode}, quantity=${quantity}, price=${price} :: ${response.data.msg1}`
        );
        throw new Error(response.data.msg1);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(
        `Failed to place a buy order: asset=${asset.hname}, ticker=${asset.shcode}, quantity=${quantity}, price=${price} (${error.message})`
      );
    }
  };

  const [spreadOver, setSpreadOver] = useState('buy1d');
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [orderPrice, setOrderPrice] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSpreadOver('buy1d');
    setOrderQuantity(0);
    setOrderPrice(0);
  };

  const [assetPrice, setAssetPrice] = useState(null);
  const [diff, setDiff] = useState(null);
  const [ratio, setRatio] = useState(null);
  const [buyOneTime, setBuyOneTime] = useState(null);

  useEffect(() => {
    if (!isEmptyObject(credentials) && open) {
      (async () => {
        const assetData = await getAssetPrice();

        setAssetPrice(assetData.assetPrice);
        setDiff(assetData.diff);
        setRatio(assetData.ratio);
        setBuyOneTime(getAssetBuyPreference(asset.shcode));
      })();
    }
  }, [open]);

  return (
    <div>
      <Button
        size='small'
        variant={'outlined'}
        sx={{ minWidth: { xs: '48px', md: '64px' } }}
        disabled={!isMarketOpen(country) ? true : false}
        onClick={handleClickOpen}
      >
        Buy
      </Button>

      {open && (
        <Dialog
          maxWidth={'xs'}
          PaperProps={{ sx: { width: '400px' } }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
              }
            }
          }}
          open={open}
          onClose={handleClose}
        >
          {isEmptyObject(credentials) ? (
            <>
              <DialogTitle textAlign={'center'}>Invalid KIS Account Credentials</DialogTitle>
              <DialogContent
                dividers
                sx={{
                  padding: '1rem auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Typography fontSize={14}>
                  Please check your KIS account credentials to access your porfolio and place any trading orders.
                </Typography>
                <Button sx={{ marginTop: 4, marginBottom: 2 }} variant='outlined' onClick={() => onClick('/profile')}>
                  Check Credentials <ArrowForwardIcon sx={{ marginLeft: '0.5rem' }} />
                </Button>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle textAlign={'center'}>Buy Order Details</DialogTitle>
              <DialogContent
                dividers
                sx={{
                  padding: '1rem auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant={'body1'} color={'primary'} m={2}>
                  {asset.hname}
                </Typography>
                <Stack direction='row' display='flex' justifyContent='center' alignItems='center' gap={2} mb={2}>
                  <Typography variant={'body1'}>
                    {isDomestic(country)
                      ? Number(assetPrice).toLocaleString('en-us')
                      : '$' + Number(assetPrice).toFixed(2).toLocaleString('en-us')}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    color={Number(ratio) > 0 ? TICKCOLOR_UP : Number(ratio) < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL}
                  >
                    {Number(ratio) > 0 ? <ArrowDropUpIcon /> : Number(ratio) < 0 ? <ArrowDropDownIcon /> : ''}
                    {isDomestic(country)
                      ? Math.abs(Number(diff)).toLocaleString('en-us')
                      : '$' + Math.abs(Number(diff)).toFixed(2).toLocaleString('en-us')}
                    {Number(ratio) > 0 ? <ArrowDropUpIcon /> : Number(ratio) < 0 ? <ArrowDropDownIcon /> : ''}
                    {Math.abs(Number(ratio)).toFixed(2).toLocaleString('en-us')}%
                  </Typography>
                </Stack>

                {buyPrice === 'market' && buyQuantity === 'default' && buyPeriod === 'once' && (
                  <Typography variant='h6' color={'primary'} fontWeight={700} mt={2} mb={2}>
                    Sure to buy this asset?
                  </Typography>
                )}

                {buyPrice === 'limit' && (
                  <TextField
                    autoFocus
                    margin='dense'
                    id='orderPrice'
                    label={
                      <Typography variant='headline' component='p' fontSize={13}>
                        Order Price
                      </Typography>
                    }
                    helperText='Enter an order price of an asset'
                    type='number'
                    size='small'
                    fullWidth
                    variant='outlined'
                    sx={{
                      marginBottom: 2
                    }}
                    value={orderPrice}
                    onChange={e => setOrderPrice(e.target.value)}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                )}

                {buyQuantity === 'custom' && (
                  <TextField
                    autoFocus={buyPrice !== 'limit'}
                    margin='dense'
                    id='orderQuantity'
                    label={
                      <Typography variant='headline' component='p' fontSize={13}>
                        Order Quantity
                      </Typography>
                    }
                    helperText='Enter an order quantity of an asset'
                    type='number'
                    size='small'
                    fullWidth
                    variant='outlined'
                    sx={{
                      marginBottom: 2
                    }}
                    value={orderQuantity}
                    onChange={e => setOrderQuantity(e.target.value)}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                )}

                {buyPeriod === 'spread' && !buyOneTime && (
                  <Box sx={{ p: 2, border: '1px solid rgba(255, 255, 255, 0.12)', borderRadius: '4px' }}>
                    <FormControl>
                      <FormLabel id='buyspread'>
                        <Typography fontSize={14}>Spread Over</Typography>
                      </FormLabel>
                      <RadioGroup
                        row
                        name='buySpreadOver'
                        size='small'
                        value={spreadOver}
                        onChange={e => setSpreadOver(e.target.value)}
                        sx={{ display: 'flex', gap: 4 }}
                      >
                        <FormControlLabel
                          value='buy1d'
                          control={<Radio size='small' fontSize={14} />}
                          label={
                            <Typography fontSize={14} color={'text.secondary'}>
                              1 day
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value='buy5d'
                          control={<Radio size='small' />}
                          label={
                            <Typography fontSize={14} color={'text.secondary'}>
                              5 days
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value='buy20d'
                          control={<Radio size='small' />}
                          label={
                            <Typography fontSize={14} color={'text.secondary'}>
                              20 days
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <Typography fontSize={12} color={'text.secondary'} alignSelf='flex-start' pl={0.5}>
                        Select a spread over period to buy
                      </Typography>
                    </FormControl>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    onPurchase(asset, assetPrice);
                    handleClose();
                  }}
                >
                  Buy
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default BuyAsset;
