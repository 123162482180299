import { Box, Toolbar } from '@mui/material';
import Appbar from './Appbar';

const Header = ({ children }) => {
  return (
    <Box display='flex'>
      <Appbar />

      <Box sx={{ flexGrow: 1 }}>
        <Toolbar sx={{ minHeight: { xs: '66px', md: '68px' } }} />
        {children}
      </Box>
    </Box>
  );
};

export default Header;
