import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAvatar from '@mui/material/Avatar';
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import bgHero from '../assets/hero.png';
import kisLogo from '../assets/kisLogo.png';
import stockPicks from '../assets/stock_picks.png';
import stockAnalysis from '../assets/stock_analysis.png';
import autoTrading from '../assets/trading.png';
import personalFund from '../assets/personal_fund.png';
import cta from '../assets/cta.png';
import { Link } from 'react-router-dom';
import ArrowForward from '@mui/icons-material/ArrowForward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme }) => ({
  border: 'none',
  backgroundColor: 'rgba(20, 29, 56)',
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled(props => <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />)(
  ({ theme }) => ({
    backgroundColor: 'rgba(20, 29, 56)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      padding: '20px 0'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      padding: '30px 10px 30px 0'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root, & .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root':
      {
        color: '#d9d9d9',
        [theme.breakpoints.up('md')]: {
          fontSize: '36px'
        }
      }
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '20px 10px',
    lineHeight: '24px'
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
    padding: '30px 30px 30px 38px',
    lineHeight: '30px'
  }
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: '#00AEEF',
  fontWeight: 700,
  [theme.breakpoints.down('md')]: {
    width: '20px',
    height: '20px',
    fontSize: '14px',
    marginRight: '10px'
  },
  [theme.breakpoints.up('md')]: {
    width: '24px',
    height: '24px',
    fontSize: '16px',
    marginRight: '14px'
  }
}));

const Landing = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container maxWidth='false' sx={{ px: { xs: 0 }, overflow: 'hidden' }}>
      <section id='hero' className={'hero'}>
        <Box
          display={'grid'}
          gridTemplateColumns={{ xs: '1fr', lg: '1.3fr 0.7fr' }}
          gap={{ xs: '50px', lg: 2 }}
          width={{ lg: '1200px', xl: '1440px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            textAlign={{ xs: 'left', lg: 'left' }}
            px={{ xs: '30px', lg: 0 }}
          >
            <Typography
              sx={{
                fontSize: { xs: '30px', md: '70px' },
                fontWeight: 700,
                lineHeight: { xs: '40px', md: '82px' }
              }}
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-delay='100'
            >
              The Next <span className='text-gradient'>Generation</span>
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '30px', md: '70px' },
                fontWeight: 700,
                lineHeight: { xs: '40px', md: '82px' }
              }}
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-delay='200'
            >
              Stock Trading
            </Typography>
            <Typography
              sx={{
                mt: { xs: '30px', lg: '60px' },
                fontSize: '16px',
                color: 'rgba(255, 255, 255, 0.7)'
              }}
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-delay='300'
            >
              Get expert advice and make smarter trades with our stock advisor service.
              <br />
              Stay ahead of the market and maximize your profits.
            </Typography>
          </Box>
          <Box
            component={'img'}
            src={bgHero}
            sx={{
              margin: 'auto',
              width: { xs: '315px', sm: '420px', lg: '586px' },
              filter: 'drop-shadow(0px 0px 10px #000000)'
            }}
            order={{ xs: 0, lg: 0 }}
            loading='lazy'
            data-aos='fade-in'
            data-aos-duration='1000'
            data-aos-delay='400'
          ></Box>
        </Box>
        <Box position={'absolute'} bottom={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography textTransform='uppercase' fontSize={'14px'} fontWeight={500} color={'rgba(255, 255, 255, 0.6)'}>
            {isMobile ? 'Swipe' : 'Scroll Down'}
          </Typography>
          {isMobile ? (
            <KeyboardArrowUpIcon className='blink' sx={{ color: '#d9d9d9' }} />
          ) : (
            <KeyboardArrowDownIcon className='blink' sx={{ color: '#d9d9d9' }} />
          )}
        </Box>
      </section>

      <section id='features' className='features'>
        <Box
          width={{ lg: '1200px', xl: '1440px' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: { xs: '24px', md: '32px' }
          }}
        >
          <Box
            textAlign={'center'}
            className={'fadeIn'}
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-delay='200'
          >
            <Typography
              sx={{
                color: '#00aeef',
                fontSize: { xs: '15px', sm: '20px' },
                fontWeight: 700,
                pb: { xs: '20px', lg: '40px' },
                textTransform: 'uppercase'
              }}
            >
              Features
            </Typography>
            <Typography sx={{ fontSize: { xs: '24px', lg: '50px' }, fontWeight: 700, pb: { xs: '50px', lg: '100px' } }}>
              Everything You Need
            </Typography>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={3} mb={{ xs: '30px', md: '64px' }}>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={'center'}
              backgroundColor={'#040d28'}
              borderRadius={'30px'}
              // boxShadow={'1px 2px 8px hsl(220deg 60% 50% / 0.2)'}
              boxShadow={'1px 5px 15px hsl(225deg 73% 5% / 0.38)'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                flexBasis={{ xs: '100%', md: '50%' }}
                maxWidth={{ xs: '100%', md: '50%' }}
                p={'30px 20px'}
                pl={{ lg: '80px' }}
                height={{ lg: '480px' }}
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='500'
              >
                <Typography
                  fontSize={{ xs: '20px', md: '42px' }}
                  fontWeight={700}
                  pb={{ xs: '20px', md: '40px' }}
                  textAlign={'center'}
                >
                  Stock Picks
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} pb={'16px'} color={'rgba(255, 255, 255, 0.7)'}>
                  Select a Stock Pick among our AI-generated list of Stock Picks that best fits your trading needs.
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} color={'rgba(255, 255, 255, 0.7)'}>
                  Three Stock Picks are available to choose from - Socrates, Plato, and Egleo, and new Stock Picks will
                  be added occasionally. A Stock Pick is a pre-selected list of stocks.
                </Typography>
              </Box>
              <Box
                component={'img'}
                sx={{ width: { xs: '240px', sm: '360px', lg: '400px' } }}
                margin={'auto'}
                pb={'30px'}
                // order={{ xs: -1, md: 0 }}
                src={stockPicks}
                alt='Stock Picks'
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              ></Box>
            </Box>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={3} mb={{ xs: '30px', md: '64px' }}>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={'center'}
              backgroundColor={'#040d28'}
              borderRadius={'30px'}
              // boxShadow={'1px 2px 8px hsl(220deg 60% 50% / 0.2)'}
              boxShadow={'1px 5px 15px hsl(225deg 73% 5% / 0.38)'}
            >
              <Box
                component={'img'}
                sx={{ width: { xs: '240px', sm: '360px', lg: '400px' } }}
                margin={'auto'}
                pb={'30px'}
                order={{ xs: 1, md: 0 }}
                src={stockAnalysis}
                alt='Stock Analysis'
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              ></Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                flexBasis={{ xs: '100%', md: '50%' }}
                maxWidth={{ xs: '100%', md: '50%' }}
                p={'30px 20px'}
                pr={{ lg: '80px' }}
                height={{ lg: '480px' }}
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              >
                <Typography
                  fontSize={{ xs: '20px', md: '42px' }}
                  fontWeight={700}
                  pb={{ xs: '20px', md: '40px' }}
                  textAlign={'center'}
                >
                  Stock Analysis
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} pb={'16px'} color={'rgba(255, 255, 255, 0.7)'}>
                  eulerFX analyzes any financial assets in your portfolio or wish lists using advanced AI and
                  sophistigated algorithms, and give you trading recommendations whether to buy or sell each asset
                  daily.
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} color={'rgba(255, 255, 255, 0.7)'}>
                  Simply follow our trading recommendations to boost your profits!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={3} mb={{ xs: '30px', md: '64px' }}>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={'center'}
              backgroundColor={'#040d28'}
              borderRadius={'30px'}
              // boxShadow={'1px 2px 8px hsl(220deg 60% 50% / 0.2)'}
              boxShadow={'1px 5px 15px hsl(225deg 73% 5% / 0.38)'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                flexBasis={{ xs: '100%', md: '50%' }}
                maxWidth={{ xs: '100%', md: '50%' }}
                p={'30px 20px'}
                pl={{ lg: '80px' }}
                height={{ lg: '480px' }}
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              >
                <Typography
                  fontSize={{ xs: '20px', md: '42px' }}
                  fontWeight={700}
                  pb={{ xs: '20px', md: '40px' }}
                  textAlign={'center'}
                >
                  Auto Trading
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} pb={'16px'} color={'rgba(255, 255, 255, 0.7)'}>
                  Configure trading preferences, turn the Auto Trading option on, then our AI-powered system will do the
                  rest for you.
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} color={'rgba(255, 255, 255, 0.7)'}>
                  Sit back, relax, and enjoy your profits!
                </Typography>
              </Box>
              <Box
                component={'img'}
                sx={{ width: { xs: '240px', sm: '360px', lg: '400px' } }}
                margin={'auto'}
                pb={'30px'}
                // order={{ xs: -1, md: 0 }}
                src={autoTrading}
                alt='Auto Trading'
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              ></Box>
            </Box>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={3} mb={{ xs: 0, md: '64px' }}>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={'center'}
              backgroundColor={'#040d28'}
              borderRadius={'30px'}
              boxShadow={'1px 5px 15px hsl(225deg 73% 5% / 0.38)'}
            >
              <Box
                component={'img'}
                sx={{ width: { xs: '146px', sm: '200px', lg: '280px' } }}
                margin={'auto'}
                pb={{ xs: '30px', md: 0 }}
                order={{ xs: 1, md: 0 }}
                src={personalFund}
                alt='Personal Funds'
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              ></Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                flexBasis={{ xs: '100%', md: '50%' }}
                maxWidth={{ xs: '100%', md: '50%' }}
                p={'30px 20px'}
                pr={{ lg: '80px', xl: '100px' }}
                height={{ lg: '480px' }}
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='200'
              >
                <Typography
                  fontSize={{ xs: '20px', md: '42px' }}
                  fontWeight={700}
                  pb={{ xs: '20px', md: '40px' }}
                  textAlign={'center'}
                >
                  Personal Funds
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} pb={'16px'} color={'rgba(255, 255, 255, 0.7)'}>
                  Now you are a fund manager - Create and manage your own funds anytime, trade assets in your fund, and
                  profit from it!
                </Typography>
                <Typography fontSize={{ xs: '14px', md: '16px' }} color={'rgba(255, 255, 255, 0.7)'}>
                  No more hassle searching for which assets to trade. Get started today!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>

      <section id='cta' className='cta'>
        <Box
          width={{ lg: '1200px', xl: '1440px' }}
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={{ xs: '40px', md: 4 }}
          px={{ xs: '24px', md: '32px' }}
          // px={{ xs: '16px', md: '24px', lg: '32px' }}
          data-aos='fade-in'
          data-aos-duration='1000'
          data-aos-delay='200'
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={{ xs: 'center', md: 'start' }}
            // width={'50%'}
            // height={'480px'}
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-delay='200'
          >
            <Typography fontSize={{ xs: '24px', lg: '50px' }} fontWeight={700} pb={{ xs: '30px', lg: '40px' }}>
              Get Expert Stock Trading Advice
            </Typography>
            <Typography fontSize={'18px'} pb={{ xs: '30px', lg: '40px' }} color={'rgba(255, 255, 255, 0.7)'}>
              Join now and start generating substantial profits.
            </Typography>
            <Link to='/register'>
              <Button
                variant='contained'
                sx={{
                  color: '#111111',
                  fontSize: '18px',
                  fontWeight: 700,
                  display: 'flex',
                  borderRadius: '60px',
                  px: '48px',
                  py: '16px',
                  boxShadow: '0px 0px 20px rgba(0, 174, 239, 0.8)'
                }}
              >
                Join Now
              </Button>
            </Link>
          </Box>
          <Box
            component={'img'}
            sx={{
              width: { xs: '290px', sm: '290px', lg: '385px' }
            }}
            margin={'auto'}
            src={cta}
            alt='Join Now'
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-delay='200'
          ></Box>
        </Box>
      </section>

      <section id='partners' className='partners'>
        <Box
          width={{ lg: '1200px', xl: '1440px' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: { xs: '24px', md: '32px' }
            // px: { xs: '16px', md: '24px', lg: '32px' }
          }}
        >
          <Box
            textAlign={'center'}
            className={'fadeIn'}
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-delay='200'
          >
            <Typography
              sx={{
                color: '#00aeef',
                fontSize: { xs: '15px', sm: '20px' },
                fontWeight: 700,
                pb: { xs: '20px', lg: '40px' },
                textTransform: 'uppercase'
              }}
            >
              Partners
            </Typography>
            <Typography sx={{ fontSize: { xs: '24px', lg: '50px' }, fontWeight: 700, pb: { xs: '50px', lg: '100px' } }}>
              Strategic Partners
            </Typography>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={{ xs: '220px', sm: '464px' }}
            border={'1px solid rgba(255, 255, 255, 0.2)'}
            py={{ xs: '24px', sm: '36px' }}
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-delay='200'
          >
            <Link to='https://www.truefriend.com/' target='_blank' rel='noopener noreferrer'>
              <Box component={'img'} src={kisLogo} margin={'0 auto'} width={{ xs: '150px', sm: '290px' }}></Box>
            </Link>
          </Box>
        </Box>
      </section>

      <section id='faq' className='faq'>
        <Box
          width={{ lg: '1200px', xl: '1440px' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: { xs: '24px', md: '32px' }
            // px: { xs: '16px', md: '24px', lg: '32px' }
          }}
        >
          <Typography
            sx={{ fontSize: { xs: '24px', lg: '50px' }, fontWeight: 700, pb: { xs: '40px', lg: '60px' } }}
            data-aos='fade-in'
            data-aos-duration='1000'
            data-aos-delay='200'
          >
            Got Questions?
          </Typography>

          <Box width={'100%'} data-aos='fade-in' data-aos-duration='1000' data-aos-delay='200'>
            <Accordion defaultExpanded>
              <AccordionSummary aria-controls='panel0-content' id='panel0-header'>
                <Avatar>Q</Avatar>
                What is eulerFX?
              </AccordionSummary>
              <AccordionDetails>
                eulerFX is an innovative trading platform that leverages AI and advanced algorithms to analyze asset
                performance, predict market movements, and execute profitable trades. This sophistigated platform
                provides a comprehensive suite of tools and features to help you make informed trading decisions to
                enhance your trading potential.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls='panel1-content' id='panel1-header'>
                <Avatar>Q</Avatar>
                What is Stock Picks?
              </AccordionSummary>
              <AccordionDetails>
                Stock Pick is a list of stocks that are generated by our AI for trading based on your trading
                preferences.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls='panel2-content' id='panel2-header'>
                <Avatar>Q</Avatar>
                How do I create and manage my own funds?
              </AccordionSummary>
              <AccordionDetails>
                Once logged in, you can create your own personal funds under My Fund menu. To add or remove assets in
                personal funds, use the Search tool to find assets.
              </AccordionDetails>
            </Accordion>
            {/* <Accordion>
              <AccordionSummary aria-controls='panel3-content' id='panel3-header'>
                <Avatar>Q</Avatar>
                How does auto trading work?
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                blandit leo lobortis eget.
              </AccordionDetails>
            </Accordion> */}
            <Accordion>
              <AccordionSummary aria-controls='panel4-content' id='panel4-header'>
                <Avatar>Q</Avatar>
                What are the requirements to do Auto Trading?
              </AccordionSummary>
              <AccordionDetails>
                Unfortunately, there is a requirement to enable Auto Trading. We partner with KIS and use their open
                APIs to facilitate the process for automated trading. Therefore, you must have a KIS account to use Auto
                Trading. If you don't have a KIS account, please click here to create one.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls='panel5-content' id='panel5-header'>
                <Avatar>Q</Avatar>
                Can I trade assets manually?
              </AccordionSummary>
              <AccordionDetails>
                Absolutely! You can trade assets manually using your own brokerage account or tools. Please don't forget
                to follow our trading recommendations while trading assets.
              </AccordionDetails>
            </Accordion>
          </Box>

          <Link to='#'>
            <Button
              sx={{
                color: 'rgba(0, 174, 239)',
                display: 'flex',
                marginTop: '60px',
                padding: '10px 16px',
                textTransform: 'none',
                fontSize: '18px',
                fontWeight: 700
              }}
              endIcon={<ArrowForward />}
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-delay='200'
            >
              More Questions
            </Button>
          </Link>
        </Box>
      </section>
    </Container>
  );
};

export default Landing;
