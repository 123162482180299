import axios from 'axios';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setPreferences, setMyFunds, resetAuth } from '../../features/auth/authSlice';
import { isDomestic, sleep, tooltipStyle } from '../../lib/utils';
import AddFund from './AddFund';
import RemoveFund from './RemoveFund';
import BuyAsset from '../dashboard/BuyAsset';
import { fetchSignals } from '../../api/fetchSignals';
import { Box, Button, Chip, Link, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MyFundList = ({ fundId, myFundList }) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token, credentials, preferences } = useSelector(state => state.auth);
  const tradingMode = preferences.tradingMode;
  const [isChangedPreferences, setIsChangedPreferences] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [assets, setAssets] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: page,
    pageSize: 10
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc'
    }
  ]);

  const { appkey, appsecret } = credentials;
  const { tradingMode: env } = preferences;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const fund = myFundList[fundId];
  const fundName = myFundList[fundId].name;

  const updateMyFundList = async assetId => {
    const newFunds = JSON.parse(JSON.stringify(myFundList));

    newFunds[fundId].assets.splice(assetId, 1);

    await prepareAssetData(newFunds[fundId].assets, 0);

    setPaginationModel({ ...paginationModel, page: 0 });
    setPage(0);
    setRowCount(newFunds[fundId].assets.length);

    dispatch(setMyFunds(newFunds));
  };

  const getAssetType = async ticker => {
    const API_URL = `/api/${env.toLowerCase()}/assets/type/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return response.data[0].type;
    } catch (error) {
      console.log(error.response.data);
      return 0;
    }
  };

  const getCurrentPrice = async (country, ticker, marketCode) => {
    const API_URL = `/api/${env.toLowerCase()}/assets/price/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        },
        params: {
          userId: token._id,
          appkey,
          appsecret,
          country,
          marketCode: marketCode || ''
        }
      };

      if (isDomestic(country)) {
        const assetType = await getAssetType(ticker);

        if (assetType === 3) {
          ticker = 'Q' + ticker;
        }
      }

      const response = await axios.get(API_URL + ticker, config);

      return isDomestic(country) ? response.data.output.stck_prpr : response.data.output.last;
    } catch (error) {
      console.log(error.response.data);
      return 0;
    }
  };

  const getSignal = async (country, ticker) => {
    const tickers = [{ country, ticker }];
    const signal = await fetchSignals(token, tradingMode, tickers);

    return signal;
  };

  // const prepareAssetData = useCallback(async (fund, page) => {
  const prepareAssetData = useCallback(async (myAssets, page) => {
    setIsLoading(true);

    const assets = [];
    const pageAssets = myAssets.slice(page * paginationModel.pageSize, (page + 1) * paginationModel.pageSize);

    if (pageAssets.length > 0) {
      for (const [idx, asset] of pageAssets.entries()) {
        await sleep(env === 'SANDBOX' ? 500 : 100);

        let assetPrice = 0;
        let signal;

        try {
          assetPrice = await getCurrentPrice(asset.country, asset.ticker, asset.marketCode);
          signal = await getSignal(asset.country, asset.ticker);
        } catch (error) {
          console.log(`idx: ${idx}, asset: ${asset.name}, error: ${error.message}`);
        }

        const data = {
          id: idx,
          name: asset.name,
          ticker: asset.ticker,
          addedAt: asset.addedAt,
          priceAt: asset.priceAt,
          assetPrice,
          country: asset.country,
          marketCode: asset.marketCode || null,
          tradeSignal: signal ? signal[0].signal : 2
        };

        assets.push(data);
      }
    }

    setRows(assets);
    setIsLoading(false);
  }, []);

  const CustomNoRowsOverlay = () => (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      You have no assets.
    </Box>
  );

  const renderAssetName = params => {
    return isDomestic(params.row.country) ? (
      <Tooltip disableFocusListener arrow title={params.row.ticker} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.naver.com/item/main.naver?code=${params.row.ticker}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.value}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip disableFocusListener arrow title={params.value} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.yahoo.com/quote/${params.row.ticker}?p=${params.row.ticker}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.row.ticker}
        </Link>
      </Tooltip>
    );
  };

  const renderDate = params => {
    if (!params.value) return;

    const currentPrice = params.row.assetPrice === 0 ? 0 : Number(params.row.assetPrice.replace(/,|\$/g, ''));
    const addedPrice = params.row.priceAt === 0 ? 0 : Number(params.row.priceAt?.replace(/,|\$/g, ''));

    const dateAdded = moment(params.value).local().format('YYYY-MM-DD');
    const change = currentPrice - addedPrice;
    const changeRatio = (change / addedPrice) * 100;

    return (
      <Tooltip
        disableFocusListener
        arrow
        title={
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={1} p={1}>
            <Typography variant='body2'>Current Price:</Typography>
            <Typography variant='body2'>{params.row.assetPrice}</Typography>
            <Typography variant='body2'>Added Price:</Typography>
            <Typography variant='body2'>{params.row.priceAt}</Typography>
            <Typography variant='body2'>Change:</Typography>
            <Typography
              variant='body2'
              display='flex'
              alignItems='center'
              color={change > 0 ? 'error' : change < 0 ? 'success.main' : ''}
            >
              {change > 0 ? (
                <ArrowDropUpIcon sx={{ ml: '-6px' }} />
              ) : change < 0 ? (
                <ArrowDropDownIcon sx={{ ml: '-6px' }} />
              ) : (
                ''
              )}{' '}
              {isDomestic(params.row.country)
                ? Math.abs(change).toLocaleString('en-us') + ' (' + Math.abs(changeRatio).toFixed(2) + '%)'
                : '$' +
                  Math.abs(change).toFixed(2).toLocaleString('en-us') +
                  ' (' +
                  Math.abs(changeRatio).toFixed(2) +
                  '%)'}
            </Typography>
          </Box>
        }
        PopperProps={{ sx: tooltipStyle }}
      >
        <Typography variant='body2'>{dateAdded}</Typography>
      </Tooltip>
    );
  };

  const renderTradeSignal = params => (
    <>
      {params.row.tradeSignal === 0 ? (
        <Chip label='SELL' color='success' sx={{ width: '62px' }} />
      ) : params.row.tradeSignal === 1 ? (
        <Chip label='BUY' color='error' sx={{ width: '62px' }} />
      ) : params.row.tradeSignal === 2 ? (
        <Chip label='HOLD' color='secondary' sx={{ width: '62px' }} />
      ) : params.row.tradeSignal === 99 ? (
        <Chip label='DELISTED' />
      ) : (
        ''
      )}
    </>
  );

  const renderBuyButton = params => {
    const assetData = {
      shcode: params.row.ticker,
      hname: params.row.name,
      marketcode: params.row.marketCode || null
    };
    const country = params.row.country;

    return params.row.tradeSignal !== 99 && <BuyAsset asset={assetData} country={country} />;
  };

  const renderRemoveButton = params => {
    return (
      <RemoveFund
        fundId={fundId}
        myFundList={myFundList}
        idx={params.row.id + page * paginationModel.pageSize}
        updateMyFundList={updateMyFundList}
      />
    );
  };

  const renderAddButton = params => {
    const { name, ticker, assetPrice: price, marketCode } = params.row;
    const asset = {
      name,
      ticker,
      price,
      marketCode
    };

    return <AddFund asset={asset} />;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Asset',
      headerAlign: 'center',
      align: 'center',
      flex: isMobile ? 0.5 : 0.6,
      renderCell: renderAssetName
    },
    {
      field: 'assetPrice',
      headerName: 'Price',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      sortable: false,
      valueFormatter: (value, row) =>
        row.country === 'KOREA'
          ? Number(value).toLocaleString('en-us')
          : `$${Number(value).toFixed(2).toLocaleString('en-us')}`
    },
    { field: 'country', headerName: 'Market', headerAlign: 'center', align: 'center', flex: 0.3 },
    {
      field: 'addedAt',
      headerName: 'Date Added',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: isMobile ? 0.2 : 0.3,
      renderCell: renderDate
    },
    {
      field: 'tradeSignal',
      headerName: 'Signal',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.2,
      renderCell: renderTradeSignal
    },
    {
      field: 'buyBtn',
      headerName: 'Trade',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.2,
      sortable: false,
      renderCell: renderBuyButton
    },
    {
      field: 'removeBtn',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.1,
      sortable: false,
      renderCell: renderRemoveButton
    },
    {
      field: 'addBtn',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.1,
      sortable: false,
      renderCell: renderAddButton
    }
  ];

  const handleSetAutoTrading = e => {
    const preferenceData = {
      tradingPriority: 'myFund',
      myFund: fundName
    };

    const newPreferences = { ...preferences, ...preferenceData };

    dispatch(setPreferences(newPreferences));
    setIsChangedPreferences(true);
  };

  // const handlePageClick = useCallback(async (params, assets) => {
  const handlePageClick = async (params, assets) => {
    await prepareAssetData(assets, params.page);

    setPaginationModel({ ...paginationModel, page: params.page });
    setPage(params.page);
  };

  const handleSortChange = async newSortModel => {
    setSortModel(newSortModel);

    if (newSortModel.length === 0) return;

    const { field: sortKey, sort: sortOrder } = newSortModel[0];
    const sortedAssets = [...assets];

    if (sortKey === 'name') {
      sortedAssets.sort((a, b) => (sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)));
    } else if (sortKey === 'country') {
      sortedAssets.sort((a, b) =>
        sortOrder === 'asc' ? a.country.localeCompare(b.country) : b.country.localeCompare(a.country)
      );
    } else if (sortKey === 'addedAt') {
      sortedAssets.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.addedAt === undefined ? -1 : b.addedAt === undefined ? 1 : a.addedAt.localeCompare(b.addedAt);
        } else {
          return b.addedAt === undefined ? -1 : a.addedAt === undefined ? 1 : b.addedAt.localeCompare(a.addedAt);
        }
      });
    } else if (sortKey === 'tradeSignal') {
      sortedAssets.sort((a, b) =>
        sortOrder === 'asc' ? a.tradeSignal - b.tradeSignal : b.tradeSignal - a.tradeSignal
      );
    }

    await prepareAssetData(sortedAssets, 0);

    setAssets(sortedAssets);
    setPaginationModel({ ...paginationModel, page: 0 });
    setPage(0);
  };

  const prep = useCallback(async () => {
    if (fund.assets.length > 0) {
      await prepareAssetData(fund.assets, 0);

      setAssets(fund.assets);
      setRowCount(fund.assets.length);
    }
  }, []);

  useEffect(() => {
    if (isChangedPreferences) {
      toast.success(`Successfully set ${fundName} to auto trading`);
      setIsChangedPreferences(false);
    }

    prep();

    return () => {
      dispatch(resetAuth());
    };
  }, [isChangedPreferences, fundName, dispatch, prep]);

  return (
    <Box width={'100%'}>
      <DataGrid
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={paginationModel => handlePageClick(paginationModel, assets)}
        sortModel={sortModel}
        onSortModelChange={newSortModel => handleSortChange(newSortModel)}
        sortingOrder={['asc', 'desc']}
        dense
        autoHeight
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        loading={isLoading}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        columnVisibilityModel={{
          name: true,
          addedAt: !isMobile ? true : false,
          assetPrice: !isMobile ? true : false,
          country: !isMobile ? true : false,
          tradeSignal: true,
          buyBtn: true,
          removeBtn: true,
          addBtn: fundId === 3 ? true : false
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true
          }
        }}
        pageSizeOptions={[10]}
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
          '& .MuiDataGrid-columnHeader': { backgroundColor: '#001021' }
        }}
      />

      {fundName !== 'WATCH LIST' ? (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 2, sm: 4 }}
          mt={4}
          display='flex'
          justifyContent='center'
        >
          <Button
            variant='contained'
            disabled={preferences.tradingPriority === 'myFund' && preferences.myFund === fundName ? true : false}
            sx={{ width: { sm: '250px' } }}
            fullWidth={isMobile ? true : false}
            onClick={handleSetAutoTrading}
          >
            Set Auto Trading
          </Button>
        </Stack>
      ) : null}
    </Box>
  );
};

export default MyFundList;
