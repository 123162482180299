import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import portfolioService from './portfolioService';

let portfolio = [];
let portfolioUS = [];
let accountBalance = 0;
let tickers = [];

const initialState = {
  portfolio,
  portfolioUS,
  accountBalance,
  tickers,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

// Retrieve user portfolio from KIS
export const getPortfolio = createAsyncThunk('portfolio/get', async (country, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token;
    const { appkey, appsecret, accountNumber, accountCode } = thunkAPI.getState().auth.credentials;
    const { tradingMode } = thunkAPI.getState().auth.preferences;
    const params = { userId: token._id, appkey, appsecret, accountNumber, accountCode, country, tradingMode };

    return await portfolioService.getPortfolio(token, params);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Retrieve user portfolio from KIS
export const searchTickers = createAsyncThunk('ticker/search', async (ticker, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token;
    const { tradingMode } = thunkAPI.getState().auth.preferences;
    const params = { userId: token._id, tradingMode, ticker };

    return await portfolioService.searchTickers(token, params);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    resetPortfolio: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    clearPortfolio: state => {
      state.portfolio = [];
      state.portfolioUS = [];
      state.tickers = [];
      state.accountBalance = 0;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPortfolio.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        action.payload?.domestic
          ? (state.portfolio = action.payload.portfolio)
          : (state.portfolioUS = action.payload.portfolioUS);
        if (action.payload?.domestic) {
          state.accountBalance = action.payload.accountBalance;
          state.nass_amt = action.payload.nass_amt;
        }
      })
      .addCase(getPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(searchTickers.pending, state => {
        state.isLoading = true;
      })
      .addCase(searchTickers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tickers = action.payload;
        console.log(action.payload);
      })
      .addCase(searchTickers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.tickers = [];
      });
  }
});

export const { resetPortfolio, clearPortfolio } = portfolioSlice.actions;
export default portfolioSlice.reducer;
