import axios from 'axios';

export const fetchSignals = async (token, env, tickers) => {
  try {
    const API_URL = `/api/${env.toLowerCase()}/assets/signals`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      },
      params: {
        tickers: tickers
      }
    };

    const response = await axios.get(API_URL, config);

    return response.data;
  } catch (error) {
    console.log(error);

    return null;
    // throw error;
  }
};
