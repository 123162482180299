import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setCredentials, resetAuth } from '../../features/auth/authSlice';
import { ENV } from '../../lib/utils';
import Spinner from '../Spinner';

import { useForm } from 'react-hook-form';
import { Box, Button, Container, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
// import { checkGridRowIdIsValid } from '@mui/x-data-grid';

const Credentials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, credentials, preferences, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);
  const isSandbox = preferences?.tradingMode === undefined || preferences?.tradingMode === ENV.SANDBOX;
  const isMobile = useMediaQuery('(max-width: 600px)');

  const {
    handleSubmit,
    register,
    unregister,
    reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful }
  } = useForm({
    defaultValues: {
      appkey: credentials.appkey || '',
      appsecret: credentials.appsecret || '',
      accountNumber: credentials.accountNumber || '',
      accountCode: credentials.accountCode || ''
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      toast.success('Credentials saved!');
      reset({
        appkey: '',
        appsecret: '',
        accountNumber: '',
        accountCode: ''
      });
    }

    if (isSuccess) {
      // toast.success('Credentials saved!');
    }

    if (isError) {
      toast.error(message);
    }

    return () => {
      unregister(['appkey', 'appsecret', 'accountNumber', 'accountCode']);
      dispatch(resetAuth());
    };
  }, [isSubmitSuccessful, isSuccess, isError, message, dispatch, reset, unregister]);

  const onSubmit = credentials => {
    dispatch(setCredentials({ credentials, isSandbox }));
  };

  if (!token) {
    return navigate('/login');
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={{ xs: 2, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <KeyIcon fontSize={isMobile ? 'medium' : 'large'} />
        <Typography component='p' fontSize={{ xs: '1.2rem', sm: '1.5rem' }}>
          <Typography
            component='span'
            color={'primary'}
            fontWeight={700}
            pr={1}
            fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
          >
            {isSandbox ? 'SANDBOX' : 'PRODUCTION'}
          </Typography>
          Credentials
        </Typography>
      </Typography>
      <Typography variant={'body2'} component={'p'} textAlign={'center'} mb={6} color={'text.secondary'}>
        Credentials are required to perform trading transactions with Korea Investment and Securities.
      </Typography>

      <Box maxWidth={{ xs: '360px', sm: '600px' }} sx={{ width: '100%', margin: 'auto', typography: 'body2' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={4} gap={{ xs: '0.5rem', sm: '1rem' }} alignItems={'center'}>
            <TextField
              fullWidth
              label='App Key'
              type='text'
              {...register('appkey', {
                value: credentials.appkey,
                required: 'App Key is required'
              })}
              error={!!errors.appkey}
              helperText={errors.appkey?.message}
              sx={{
                '& input': {
                  fontSize: '14px'
                },
                '& input::label': {
                  fontSize: '14px'
                }
              }}
            />
            <TextField
              fullWidth
              label='App Secret'
              type='text'
              {...register('appsecret', {
                value: credentials.appsecret,
                required: 'App Secret is required'
              })}
              error={!!errors.appsecret}
              helperText={errors.appsecret?.message}
            />
            <TextField
              fullWidth
              label='Account Number'
              type='number'
              {...register('accountNumber', {
                value: credentials.accountNumber,
                required: 'Account Number is required'
              })}
              error={!!errors.accountNumber}
              helperText={errors.accountNumber?.message}
            />
            <TextField
              fullWidth
              label='Account Code'
              type='text'
              {...register('accountCode', {
                value: credentials.accountCode,
                required: 'Account Code is required'
              })}
              error={!!errors.accountCode}
              helperText={errors.accountCode?.message}
            />
            <Button type='submit' variant='contained' color='primary' fullWidth disabled={!isDirty || !isValid}>
              Save
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};

export default Credentials;
