import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword, resetAuth } from '../features/auth/authSlice';
import { toast } from 'react-toastify';

import { useForm } from 'react-hook-form';
import { Box, Button, Container, Link, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isSuccess, isError, message } = useSelector(state => state.auth);
  const [isComplete, setIsComplete] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful }
  } = useForm({
    defaultValues: {
      email: ''
    }
  });
  const email = watch('email');

  useEffect(() => {
    if (isSuccess) {
      setIsComplete(true);
    }

    if (isError) {
      toast.error(message);
    }

    return () => {
      dispatch(resetAuth());
    };
  }, [isSubmitSuccessful, reset, isSuccess, isError, message, dispatch]);

  const onSubmit = (email, event) => {
    event?.preventDefault();
    dispatch(forgotPassword(email));
  };

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component={'h1'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={{ xs: 2, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <LockIcon fontSize={isMobile ? 'medium' : 'large'} /> Forgot Password
      </Typography>
      <Typography variant={'body2'} component={'p'} textAlign={'center'} mb={6} color={'text.secondary'}>
        Enter your email address to reset password
      </Typography>

      {isComplete ? (
        <>
          <Typography variant='h4' textAlign={'center'} color={'success.main'} mt={4} mb={4}>
            SUCCESS!
          </Typography>
          <Typography variant='body1'>An email with password reset instructions has been sent to {email}.</Typography>
          <Typography variant='body1' mt={2}>
            Please check your inbox and follow the instructions to reset your password.
          </Typography>

          <Button variant='contained' color='primary' style={{ marginTop: '2rem' }} onClick={() => navigate('/login')}>
            Back to Log In
          </Button>
        </>
      ) : (
        <Box maxWidth={{ xs: '360px', sm: '600px' }} sx={{ width: '100%', margin: 'auto', typography: 'body2' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack spacing={4} alignItems={'center'}>
              <TextField
                autoComplete='on'
                fullWidth
                size='small'
                label='Email'
                type='text'
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email is required'
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'Invalid email format'
                  }
                })}
                error={!!errors.email}
                helperText={isError ? message : errors.email?.message || 'Enter your email address'}
              />

              <Button type='submit' variant='contained' color='primary' fullWidth disabled={!isDirty}>
                Send Reset Code
              </Button>

              <Stack
                width={'100%'}
                direction='row'
                justifyContent={'flex-end'}
                alignItems={'center'}
                style={{ marginTop: '1rem' }}
              >
                <Typography variant='body2' display={'flex'} gap={1}>
                  Back to
                  <Link
                    component={'button'}
                    variant='body2'
                    color='primary'
                    underline='none'
                    onClick={() => navigate('/login')}
                  >
                    Log In
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </form>
        </Box>
      )}
    </Container>
  );
};

export default ForgotPassword;
