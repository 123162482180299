import { useLocation } from 'react-router-dom';
import { Box, Container, Divider, Link, Typography } from '@mui/material';
import Logo from '../assets/logo.png';

const Footer = () => {
  const { pathname } = useLocation();
  const isRoot = pathname === '/';

  return (
    <>
      {isRoot && (
        <Container
          maxWidth='100%'
          sx={{
            // px: { xs: 0 },
            px: { xs: '24px', md: '32px' },
            mt: 'auto',
            overflow: 'hidden',
            background: 'rgba(2, 11, 36)',
            zIndex: theme => theme.zIndex.drawer + 1
          }}
        >
          <Box
            component='footer'
            width={{ xs: '100%', lg: '1440px' }}
            margin={'0 auto'}
            // px={3}
            py={'30px'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-between',
              gap: { xs: '25px', sm: '30px' }
            }}
            alignContent={'center'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: { xs: 1, sm: 2, md: 4 }
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: { xs: 2, sm: 3 } }}
              >
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  component='img'
                  src={Logo}
                  alt='eulerFX'
                  width={'100px'}
                ></Box>
                <Typography variant='body2' color={'rgba(255, 255, 255, 0.5)'}>
                  Copyright &copy; {new Date().getFullYear()} &nbsp;&nbsp; All rights reserved.
                  {/* Copyright &copy; {new Date().getFullYear()} &nbsp;&nbsp; eulerFX &nbsp;&nbsp; All rights reserved. */}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}>
                <Typography variant='body2'>
                  <Link href='/legal/privacy' target='_blank' rel='noreferrer' underline='none' color='inherit'>
                    Privacy Policy
                  </Link>
                </Typography>
                <Typography variant='body2'>
                  <Link href='/legal/tos' target='_blank' rel='noreferrer' underline='none' color='inherit'>
                    Terms of Service
                  </Link>
                </Typography>
              </Box>
            </Box>
            {isRoot && (
              <>
                <Divider></Divider>
                <Typography variant='body2' color={'rgba(255, 255, 255, 0.3)'} textAlign={'justify'}>
                  The information and services provided by eulerFX is solely for informational purposes and not a
                  substitute for professional financial advice. Past performance is not a predictor of future results.
                  Individual investment results may vary. It's crucial to do your research, consult with financial
                  experts, and align your financial objectives and risk tolerance before investing. eulerFX creators and
                  operators are not liable for any financial losses incurred from using this information. Users should
                  exercise caution, seek professional advice, and be prepared for the risks involved in trading and
                  investing in financial assets, only investing what they can afford to lose. The information in this
                  application is believed to be reliable but may not always be accurate or current. Users should verify
                  information independently and not solely rely on this application for financial decisions. By using
                  eulerFX, you acknowledge that it doesn't offer financial advice and agree to consult a qualified
                  financial advisor before making investment decisions.
                </Typography>
              </>
            )}
          </Box>
        </Container>
      )}
    </>
  );
};

export default Footer;
