import moment from 'moment-timezone';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL, tooltipStyle } from '../../lib/utils';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import LoadingButton from '@mui/lab/LoadingButton';
import AssistantIcon from '@mui/icons-material/Assistant';
import { simulatefund } from '../../api/simulatefund';

const Simulation = () => {
  const navigate = useNavigate();

  const { token, preferences } = useSelector(state => state.auth);
  const tradingMode = preferences.tradingMode;

  const today = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'd').format('YYYY-MM-DD');
  let fromDate = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'M').format('YYYY-MM-DD');

  const [result, setResult] = useState(null);
  const [fund, setFund] = useState('PRIMARY');
  const [period, setPeriod] = useState('1M');
  const [ai, setAI] = useState('AI0');
  const [maxBudget, setMaxBudget] = useState('0.3');
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [plotData, setPlotData] = useState([]);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const tableStyle = {
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    lineHeight: '52px',
    paddingLeft: '1rem'
  };

  const series = [
    {
      type: 'bar',
      yAxisId: 'balance',
      label: 'Account Balance',
      color: 'lightgray',
      data: plotData.map(day => day.accountBalance),
      highlightScope: { highlight: 'item' }
    },
    {
      type: 'line',
      yAxisId: 'profit',
      label: 'Profit',
      color: '#00AEEF',
      data: plotData.map(day => day.profit),
      highlightScope: { highlight: 'item' }
    },
    {
      type: 'line',
      yAxisId: 'profit',
      color: 'red',
      label: 'Profit Ratio',
      data: plotData.map(day => day.profitRatio),
      valueFormatter: (v, { dataIndex }) => {
        if (!v) return;

        const { profitRatio } = plotData[dataIndex];
        return profitRatio.toFixed(2) + '%';
      }
    }
  ];

  const displayResult = async () => {
    setResult(null);

    setIsLoading(true);

    try {
      const result = await simulatefund(token, tradingMode, fund, startDate, endDate, ai, maxBudget);

      setResult({
        fund,
        startDate,
        endDate,
        ai:
          ai === 'AI1'
            ? '#1'
            : ai === 'AI2'
            ? '#2'
            : ai === 'AI3'
            ? '#3'
            : ai === 'AI4'
            ? '#4'
            : ai === 'AI5'
            ? '#5'
            : 'AI0',
        accountBalance: result.accountBalance,
        cashBalance: result.cashBalance,
        profit: result.profit,
        profitRatio: result.profitRatio,
        performanceSummary: result.performanceSummary
      });

      setPlotData(result.performanceSummary);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }

    const month = period === '1M' || period === 'CUSTOM' ? 1 : period === '3M' ? 3 : 6;
    const fromDate = moment.tz(new Date(), 'Asia/Seoul').subtract(month, 'M').format('YYYY-MM-DD');

    setStartDate(fromDate);
  }, [token, navigate, period]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
      >
        <AssistantIcon fontSize={isMobile ? 'medium' : 'large'} /> Fund Simulation
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', mt: 10, typography: 'body1' }}
      >
        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={2}
        >
          <Typography variant='body2' justifySelf={'self-end'}>
            Fund
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={fund}
            exclusive
            onChange={(e, fund) => {
              if (fund !== null) setFund(fund);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='PRIMARY'>PRIMARY</ToggleButton>
            <ToggleButton value='SECONDARY'>SECONDARY</ToggleButton>
            <ToggleButton value='INDEX'>INDEX</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' justifySelf={'self-end'}>
            Period
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={period}
            exclusive
            onChange={(e, period) => {
              if (period !== null) setPeriod(period);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='1M'>1 mon</ToggleButton>
            <ToggleButton value='3M'>3 mon</ToggleButton>
            <ToggleButton value='6M'>6 mon</ToggleButton>
            <ToggleButton value='CUSTOM'>Custom</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {period === 'CUSTOM' && (
          <Stack
            display={'grid'}
            gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
            alignItems={'center'}
            gap={0}
            px={{ xs: 0, sm: 18 }}
            mt={3}
          >
            <Typography variant='body2' justifySelf={'self-end'}>
              {''}
            </Typography>
            <Box display={'flex'} alignItems={'center'}>
              <DatePicker
                label='From'
                sx={{ width: '180px' }}
                minDate={moment('2024-01-01')}
                maxDate={moment(today)}
                value={moment(startDate)}
                onChange={newValue => setStartDate(moment(newValue).format('YYYY-MM-DD'))}
              />
              <Typography px={2}>-</Typography>
              <DatePicker
                label='To'
                sx={{ width: '180px' }}
                minDate={moment(startDate)}
                maxDate={moment(today)}
                value={moment(endDate)}
                onChange={newValue => setEndDate(moment(newValue).format('YYYY-MM-DD'))}
              />
            </Box>
          </Stack>
        )}

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' justifySelf={'self-end'}>
            Algorithm
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={ai}
            exclusive
            onChange={(e, ai) => {
              if (ai !== null) setAI(ai);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='AI0'>AI 0</ToggleButton>
            <ToggleButton value='AI1'>AI 1</ToggleButton>
            <ToggleButton value='AI2'>AI 2</ToggleButton>
            <ToggleButton value='AI3'>AI 3</ToggleButton>
            <ToggleButton value='AI4'>AI 4</ToggleButton>
            <ToggleButton value='AI5'>AI 5</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' justifySelf={'self-end'}>
            Max Budget
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={maxBudget}
            exclusive
            onChange={(e, maxBudget) => {
              if (maxBudget !== null) setMaxBudget(maxBudget);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='0.3'>30%</ToggleButton>
            <ToggleButton value='0.5'>50%</ToggleButton>
            <ToggleButton value='1.0'>100%</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} gap={4} mt={4}>
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            startIcon={<AssistantIcon />}
            variant='contained'
            sx={{ width: '150px' }}
            onClick={() => displayResult()}
          >
            Simulate
          </LoadingButton>
        </Stack>

        <Box
          display={'flex'}
          justifyContent={'center'}
          gap={4}
          maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
          sx={{ width: '100%', margin: 'auto', mt: 4, typography: 'body1' }}
        >
          {result && (
            <Stack spacing={4} sx={{ width: '100%', margin: { xs: '2rem auto', md: '4rem auto' } }}>
              {/* simulation result summary */}
              <Box
                display={'flex'}
                gap={2}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant='h6'>{result.fund} Simulation Result</Typography>
                <Typography variant='body1' color={'primary'}>
                  Algorithm: {result.ai}
                </Typography>
              </Box>

              <Box
                display={'grid'}
                gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
                border={'1px solid rgba(255, 255, 255, 0.2)'}
                borderRadius={'6px'}
              >
                <Typography style={tableStyle} backgroundColor='#001021'>
                  Start Date
                </Typography>
                <Typography style={tableStyle}>{result.startDate}</Typography>
                <Typography style={tableStyle} backgroundColor='#001021'>
                  End Date
                </Typography>
                <Typography style={tableStyle}>{result.endDate}</Typography>
                <Typography style={tableStyle} backgroundColor='#001021'>
                  Account Balance
                </Typography>
                <Typography style={tableStyle}>{result.accountBalance?.toLocaleString('en-us')}</Typography>
                <Typography style={tableStyle} backgroundColor='#001021'>
                  Cash Balance
                </Typography>
                <Typography style={tableStyle}>{result.cashBalance?.toLocaleString('en-us')}</Typography>
                <Typography style={tableStyle} backgroundColor='#001021'>
                  Profit
                </Typography>
                <Typography
                  style={tableStyle}
                  color={
                    result.profit && result.profit > 0
                      ? TICKCOLOR_UP
                      : result.profit && result.profit < 0
                      ? TICKCOLOR_DOWN
                      : TICKCOLOR_NEUTRAL
                  }
                >
                  {result.profit?.toLocaleString('en-us')}
                </Typography>
                <Typography style={tableStyle} backgroundColor='#001021'>
                  Profit %
                </Typography>
                <Typography
                  style={tableStyle}
                  color={
                    result.profitRatio && result.profitRatio > 0
                      ? TICKCOLOR_UP
                      : result.profitRatio && result.profitRatio < 0
                      ? TICKCOLOR_DOWN
                      : TICKCOLOR_NEUTRAL
                  }
                >
                  {result.profitRatio?.toFixed(2).toLocaleString('en-us') + '%'}
                </Typography>
              </Box>

              {/* simulation result chart */}
              <Box display={'flex'} gap={2} pt={4} flexDirection={'column'}>
                <Typography variant='h6'>Performance Chart</Typography>

                <ResponsiveChartContainer
                  series={series}
                  height={450}
                  margin={{ top: 16 }}
                  xAxis={[
                    {
                      id: 'date',
                      data: plotData.map(day => new Date(day.date)),
                      // data: plotData.map(day => moment(day.date).format('MM/DD')),
                      scaleType: 'band',
                      valueFormatter: value => value.toLocaleDateString()
                    }
                  ]}
                  yAxis={[
                    {
                      id: 'profit',
                      scaleType: 'linear',
                      valueFormatter: value => `${(value / 1000000).toLocaleString()}M`
                    },
                    {
                      id: 'balance',
                      scaleType: 'linear',
                      valueFormatter: value => `${(value / 1000000).toLocaleString()}M`
                    }
                  ]}
                >
                  <ChartsAxisHighlight x='line' />
                  <LinePlot />
                  <LineHighlightPlot />
                  <ChartsXAxis
                    label='Date'
                    position='bottom'
                    axisId='date'
                    tickInterval={(value, index) => {
                      return index % 3 === 0;
                    }}
                    tickLabelStyle={{ fontSize: 12 }}
                  />
                  <ChartsYAxis
                    label='Profit (KRW)'
                    position='left'
                    axisId='profit'
                    tickLabelStyle={{ fontSize: 10 }}
                    sx={{
                      [`& .${axisClasses.label}`]: {
                        transform: 'translateX(-12px)'
                      }
                    }}
                  />
                  <ChartsYAxis
                    label='Account Balance (KRW)'
                    position='right'
                    axisId='balance'
                    tickLabelStyle={{ fontSize: 10 }}
                    sx={{
                      [`& .${axisClasses.label}`]: {
                        transform: 'translateX(12px)'
                      }
                    }}
                  />
                  <ChartsTooltip />
                </ResponsiveChartContainer>
              </Box>

              {/* simulation result details with daily data */}
              <Box display={'flex'} gap={2} pt={4} flexDirection={{ xs: 'column', md: 'row' }}>
                <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
                  <Typography variant='h6'>Daily Performance</Typography>
                </Box>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={1}
                mt={0}
                sx={{ overflowWrap: 'anywhere' }}
              >
                {result.performanceSummary.length === 0 ? (
                  <Typography>No trade transactions during the selected period.</Typography>
                ) : (
                  result.performanceSummary.map(dailyResult => (
                    <Grid container spacing={1} key={dailyResult.date}>
                      <Grid item xs={3}>
                        <Typography color={'primary'}>Date: {dailyResult.date}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Account Balance</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {dailyResult.accountBalance.toLocaleString('en-us')}
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Typography>Cash Balance</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {dailyResult.cashBalance.toLocaleString('en-us')}
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Typography>Asset Balance</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {dailyResult.assetBalance.toLocaleString('en-us')}
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Typography>Profit</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        color={
                          dailyResult.profit > 0
                            ? TICKCOLOR_UP
                            : dailyResult.profit < 0
                            ? TICKCOLOR_DOWN
                            : TICKCOLOR_NEUTRAL
                        }
                      >
                        {dailyResult.profit.toLocaleString('en-us')}
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Typography>Profit %</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        color={
                          dailyResult.profitRatio > 0
                            ? TICKCOLOR_UP
                            : dailyResult.profitRatio < 0
                            ? TICKCOLOR_DOWN
                            : TICKCOLOR_NEUTRAL
                        }
                      >
                        {dailyResult.profitRatio?.toFixed(2).toLocaleString('en-us') + '%'}
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={9}>
                        <Typography color={'primary'}>Portfolio</Typography>
                      </Grid>
                      {dailyResult.portfolio.length === 0 ? (
                        <>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={9}>
                            <Typography>No assets</Typography>
                          </Grid>
                        </>
                      ) : (
                        dailyResult.portfolio.map(asset => (
                          <Grid container spacing={1} key={asset.ticker} pl={'8px'} pt={'8px'}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                              <Divider></Divider>
                            </Grid>

                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                disableFocusListener
                                arrow
                                title={`${asset.name} (${asset.ticker})`}
                                PopperProps={{ sx: tooltipStyle }}
                              >
                                <Link
                                  color='primary.light'
                                  underline='none'
                                  href={`https://finance.naver.com/item/main.naver?code=${asset.ticker}`}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <Typography
                                    color={'#90caf9'}
                                    display={'inline-block'}
                                    overflow={'hidden'}
                                    whiteSpace={'nowrap'}
                                    textOverflow={'ellipsis'}
                                    maxWidth={'220px'}
                                  >
                                    {asset.name}
                                  </Typography>
                                </Link>
                              </Tooltip>
                              {/* {asset.ticker} */}
                            </Grid>
                            <Grid item xs={3}>
                              Bought Date
                            </Grid>
                            <Grid item xs={3}>
                              {asset.buyAt}
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              Bought Price
                            </Grid>
                            <Grid item xs={3}>
                              {asset.buyPrice.toLocaleString('en-us')}
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              Quantity
                            </Grid>
                            <Grid item xs={3}>
                              {asset.quantity.toLocaleString('en-us')}
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              Profit
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              color={
                                asset.profit > 0 ? TICKCOLOR_UP : asset.profit < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL
                              }
                            >
                              {asset.profit.toLocaleString('en-us')}
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              Profit %
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              color={
                                asset.profitRatio > 0
                                  ? TICKCOLOR_UP
                                  : asset.profitRatio < 0
                                  ? TICKCOLOR_DOWN
                                  : TICKCOLOR_NEUTRAL
                              }
                            >
                              {asset.profitRatio?.toFixed(2).toLocaleString('en-us') + '%'}
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              Open
                            </Grid>
                            <Grid item xs={3}>
                              {asset.open.toLocaleString('en-us')}
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              Close
                            </Grid>
                            <Grid item xs={3}>
                              {asset.close.toLocaleString('en-us')}
                            </Grid>
                          </Grid>
                        ))
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ margin: '8px 0' }}></Divider>
                      </Grid>
                    </Grid>
                  ))
                )}
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Simulation;
