import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../features/auth/authSlice';
import { toast } from 'react-toastify';

import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import LockResetIcon from '@mui/icons-material/LockReset';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const { resetToken } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const { isSuccess, isError, message } = useSelector(state => state.auth);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful }
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });
  const password = watch('password');

  useEffect(() => {
    if (isSuccess) {
      // setIsComplete(true);
      toast.success('Password reset link has sent!');
    }

    if (isError) {
      toast.error(message);
    }

    // if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful, reset]);

  const onSubmit = ({ password }) => dispatch(resetPassword({ password, resetToken }));

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component={'h1'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={{ xs: 2, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <LockResetIcon fontSize={isMobile ? 'medium' : 'large'} /> Reset Password
      </Typography>
      <Typography variant={'body2'} component={'p'} textAlign={'center'} mb={6} color={'text.secondary'}>
        Enter a new password
      </Typography>

      <Box maxWidth={{ xs: '360px', sm: '600px' }} sx={{ width: '100%', margin: 'auto', typography: 'body2' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={4} alignItems={'center'}>
            <TextField
              size='small'
              fullWidth
              label='New Password'
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: {
                  value: true,
                  message: 'Password is required'
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ '& .MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <KeyOffIcon /> : <KeyIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              fullWidth
              size='small'
              label='Confirm Password'
              type={showPassword ? 'text' : 'password'}
              {...register('confirmPassword', {
                required: {
                  value: true,
                  message: 'Confirm Password is required'
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                },
                validate: value => value === password || 'Passwrod and Confirm Password should match'
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ '& .MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <KeyOffIcon /> : <KeyIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Button type='submit' variant='contained' color='primary' fullWidth disabled={!isDirty}>
              Reset Password
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};

export default ResetPassword;
