import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, resetAuth } from '../features/auth/authSlice';

const TokenVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (token?.token) {
      const decoded = JSON.parse(atob(token.token.split('.')[1]));
      const isTokenExpired = decoded.exp * 1000 < Date.now() ? true : false;

      if (isTokenExpired) {
        dispatch(logout(token._id));
        dispatch(resetAuth());

        return navigate('/login', { replace: true });
      }
    }
  }, [token, location, navigate, dispatch]);

  return;
};

export default TokenVerify;
