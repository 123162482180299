import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Privacy = () => {
  return (
    <Container maxWidth='lg' sx={{ mb: 10 }}>
      <Typography
        component={'h1'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        m={{ xs: 4, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        개인정보취급방침
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '800px', lg: '1000px' }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        rowGap={2}
        px={{ xs: 1, sm: 2 }}
        sx={{ width: '100%', margin: 'auto', typography: 'body2', textAlign: 'justify' }}
      >
        <Typography fontWeight={'bold'} mt={2}>
          제 1조 개인정보의 처리 목적
        </Typography>
        <Typography variant='body2'>
          (주)이글레오에프티(이하 "회사")은 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는
          이용하지 않습니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>고객 가입의사 확인</li>
          <li>고객에 대한 서비스 제공에 따른 본인 식별ㆍ인증</li>
          <li>회원자격 유지ㆍ관리</li>
          <li>서비스 공급에 따른 금액 결제</li>
          <li>교육 콘텐츠 제공</li>
          <li>서비스 및 이벤트와 관련된 공급ㆍ배송 등</li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 2조 개인정보의 처리 및 보유 기간
        </Typography>
        <Typography variant='body2'>
          (1) 회사는 정보주체로부터 개인정보를 수집할 때 동의받은 개인정보 보유ㆍ이용기간 또는 법령에 따른 개인정보
          보유ㆍ이용기간 내에서 개인정보를 처리ㆍ보유합니다.
        </Typography>
        <Typography variant='body2'>(2) 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.</Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 3조 개인정보의 제3자 제공
        </Typography>
        <Typography variant='body2'>
          회사는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우 외에는 개인정보를
          제3자에게 제공하지 않습니다.
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 4조 정보주체의 권리ㆍ의무 및 그 행사방법에 관한 사항
        </Typography>
        <Typography variant='body2'>
          정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>개인정보 열람요구</li>
          <li>오류 등이 있을 경우 정정 요구</li>
          <li>삭제요구</li>
          <li>처리정지 요구</li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 6조 만 14세 미만 아동의 개인정보보호
        </Typography>
        <Typography variant='body2'>
          회사는 만 14세 미만의 아동으로부터 개인정보를 수집하지 않도록 하기 위해 만 14세 미만의 아동인 경우 회원가입 및
          서비스 이용을 하지 못하도록 조치를 취하고 있습니다.
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 7조 처리하는 개인정보의 항목
        </Typography>
        <Typography variant='body2'>회사는 다음의 개인정보 항목을 처리하고 있습니다.</Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>
            필수항목: 로그인ID, 비밀번호, 휴대전화번호, 이메일ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보,
            결제기록
          </li>
          <li>선택항목: 회원가입경로</li>
        </Typography>
        <Typography variant='body2'>
          필수항목인 쿠키(cookie)를 이용자가 수동으로 거부할 수 있습니다. 쿠키는 이용자에게 보다 빠르고 편리한 웹사이트
          사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용합니다.
        </Typography>
        <Typography variant='body2'>&#10057; 쿠키란 무엇일까요?</Typography>
        <Typography variant='body2'>
          쿠키란 웹사이트를 운영하는 데 이용되는 서버가 이용자의 부라우저에 보내는 아주 작은 텍스트 파일로써 이용자의
          컴퓨터에 저장됩니다.
        </Typography>
        <Typography variant='body2'>&#10057; 쿠키는 왜 사용하나요?</Typography>
        <Typography variant='body2'>
          쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해
          서비스 개선에 활용합니다. 이를 통해 이용자는 보다 손쉽게 서비스를 이용할 수 있게 됩니다.
        </Typography>
        <Typography variant='body2'>&#10057; 쿠키를 수집하지 못하게 거부하고 싶다면?</Typography>
        <Typography variant='body2'>
          이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든
          쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키
          설치를 거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
        </Typography>
        <Typography variant='body2'>[설정 방법의 예]</Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>Internet Explorer의 경우: 웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정</li>
          <li>
            Chrome의 경우: 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼
            &gt; 쿠키
          </li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 8조 개인정보 파기 절차 및 방법
        </Typography>
        <Typography variant='body2'>
          회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 다만, 다른 법률에
          따라 보존하여야 하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
        </Typography>
        <Typography variant='body2'>(1) 파기절차</Typography>
        <Typography variant='body2'>
          불필요한 개인정보 및 개인정보파일은 개인정보보호책임자의 책임하에 내부방침 절차에 따라 다음과 같이 처리하고
          있습니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>개인정보의 파기보유기간이 경과한 개인정보는 종료일로부터 지체없이 파기합니다.</li>
          <li>
            개인정보파일의 파기개인정보파일의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이
            불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그 개인정보파일을
            파기합니다.
          </li>
        </Typography>
        <Typography variant='body2'>(2) 파기방법</Typography>
        <Typography variant='body2'>
          전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기하며, 종이에 출력된 개인정보는 분쇄기로
          분쇄하거나 소각을 통하여 파기합니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>
            파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 (종이의 경우 별도의 서류) 내부 방침 및 기타
            관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가
            아니고서는 다른 목적으로 이용되지 않습니다.
          </li>
          <li>
            파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에,
            개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
            개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
          </li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 9조 개인정보의 안전성 확보 조치
        </Typography>
        <Typography variant='body2'>
          회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고
          있습니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>
            정기적인 자체 감사 실시개인정보 처리 관련 안정성 확보를 위해 정기적 (분기 1회)으로 자체 감사를 실시하고
            있습니다.
          </li>
          <li>
            개인정보 처리 직원의 최소화 및 교육개인정보를 처리하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
            개인정보를 관리하는 대책을 시행하고 있습니다.
          </li>
          <li>내부관리계획의 수립 및 시행개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
          <li>
            해킹 등에 대비한 기술적 대책회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
            보안프로그램을 설치하고 주기적인 갱신ㆍ점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
            기술적/물리적으로 감시 및 차단하고 있습니다.
          </li>
          <li>
            개인정보의 암호화이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며
            중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
            있습니다.
          </li>
          <li>
            접속기록의 보관 및 위변조 방지개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속
            기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
          </li>
          <li>
            개인정보에 대한 접근 제한개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여
            개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
            접근을 통제하고 있습니다.
          </li>
          <li>
            문서보안을 위한 잠금장치 사용개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에
            보관하고 있습니다.
          </li>
          <li>
            비인가자에 대한 출입 통제개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를
            수립, 운영하고 있습니다.
          </li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 10조 권익침해 구제방법
        </Typography>
        <Typography variant='body2'>
          정보주체께서는 개인정보침해로 인한 신고나 상담이 필요하신 경우 아래 기관에 문의하시기 바랍니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>
            <Link href='http://www.kopico.go.kr' underline='none'>
              개인정보분쟁조정위원회
            </Link>{' '}
            (02-405-5150)
          </li>
          <li>
            <Link href='https://www.privacy.kisa.or.kr' underline='none'>
              한국인터넷진흥원 개인정보침해신고센터
            </Link>{' '}
            (118)
          </li>
          <li>
            <Link href='https://www.eprivacy.or.kr' underline='none'>
              정보보호마크인증위원회
            </Link>{' '}
            (02-550-9531)
          </li>
          <li>
            <Link href='https://www.spo.go.kr' underline='none'>
              대검찰청 첨단범죄수사과
            </Link>{' '}
            (02-3480-2000)
          </li>
          <li>
            <Link href='https://cyberbureau.police.go.kr' underline='none'>
              경찰청 사이버안전국
            </Link>{' '}
            (02-3150-2659)
          </li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 11조 개인정보 보호책임자
        </Typography>
        <Typography variant='body2'>
          회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
          등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>개인정보 보호책임자: 서천일</li>
          <li>연락처: 031-388-1171</li>
          <li>이메일: ciseo0929@gmail.com</li>
        </Typography>
        <Typography fontWeight={'bold'} mt={2}>
          제 12조 정책 변경에 따른 고지의무
        </Typography>
        <Typography variant='body2'>
          본 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는
          변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
        </Typography>
        <Typography variant='body2'>위 개인정보처리방침은 2023년 9월 1일부터 적용됩니다.</Typography>
        <Typography
          variant='body2'
          component='ul'
          pl={2}
          display={'flex'}
          flexDirection={'column'}
          rowGap={{ xs: '0.5rem', sm: '1rem' }}
        >
          <li>시행일자: 2023년 9월 1일</li>
          <li>최종변경일자: 2023년 8월 18일</li>
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
