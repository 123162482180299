import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defaultFunds } from '../../lib/utils';
import { Box, Container, Tab, Typography, useMediaQuery } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddchartIcon from '@mui/icons-material/Addchart';
import MyFundList from './MyFundList';
import { getMyFunds, resetAuth } from '../../features/auth/authSlice';

const MyFund = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, myFunds } = useSelector(state => state.auth);
  const myFundList = myFunds || defaultFunds;
  const [tabIndex, setTabIndex] = useState(myFundList[0].name);
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    dispatch(getMyFunds());

    return () => {
      dispatch(resetAuth());
    };
  }, [dispatch]);

  if (!token) {
    return navigate('/login');
  }

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
      >
        <AddchartIcon fontSize={isMobile ? 'medium' : 'large'} /> My Fund
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '800px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', typography: 'body1' }}
      >
        <TabContext key={tabIndex} value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(e, value) => setTabIndex(value)}
              // aria-label='My Fund Tabs'
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile={isMobile ? true : false}
            >
              {myFundList.map((fund, idx) => (
                <Tab key={idx} label={fund.name} value={fund.name} />
              ))}
            </TabList>
          </Box>
          {myFundList.map((fund, idx) => (
            <TabPanel key={idx} value={fund.name}>
              <MyFundList key={idx} fundId={idx} myFundList={myFundList} />
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Container>
  );
};

export default MyFund;
