import axios from 'axios';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmptyObject, isDomestic, COUNTRY, tooltipStyle } from '../../lib/utils';
import Spinner from '../Spinner';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Link, Tooltip, Typography, useMediaQuery } from '@mui/material';

const TradeLogList = ({ country }) => {
  const { token, credentials, preferences } = useSelector(state => state.auth);
  const { appkey, appsecret, accountNumber, accountCode } = credentials;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [trades, setTrades] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getTradeLog = async (startDate, endDate, country) => {
    try {
      const API_URL = `/api/${preferences.tradingMode.toLowerCase()}/trades/log`;

      const config = {
        headers: {
          authorization: `Bearer ${token.token}`,
          'cache-control': 'no-store',
          pragma: 'no-store',
          expires: '0'
        },
        params: {
          userId: token._id,
          appkey,
          appsecret,
          accountNumber,
          accountCode,
          startDate,
          endDate,
          country
        }
      };

      const response = await axios.get(API_URL, config);

      return isDomestic(country) ? response.data.output1 : response.data.output;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (isEmptyObject(credentials)) return;

    (async () => {
      const today = moment().format('YYYYMMDD');
      const startDate = moment().subtract(30, 'days').format('YYYYMMDD');

      const logs = await getTradeLog(startDate, today, country);

      const tradelogs = logs?.map(trade => {
        return isDomestic(country)
          ? {
              tradeDate: `${trade.ord_dt} ${trade.ord_tmd}`,
              assetName: trade.prdt_name,
              assetPrice: trade.avg_prvs,
              assetQuantity: trade.tot_ccld_qty,
              tradeStatus: trade.sll_buy_dvsn_cd === '02' ? 'buy' : 'sell',
              ticker: trade.pdno,
              country: COUNTRY.KOREA
            }
          : {
              tradeDate: `${trade.ord_dt} ${trade.thco_ord_tmd}`,
              assetName: trade.prdt_name,
              assetPrice: trade.ft_ccld_unpr3,
              assetQuantity: trade.ft_ccld_qty,
              tradeStatus: trade.sll_buy_dvsn_cd === '02' ? 'buy' : 'sell',
              ticker: trade.pdno,
              country: COUNTRY.USA
            };
      });

      tradelogs ? setTrades([...tradelogs]) : setTrades(null);

      setIsLoading(false);
    })();
  }, []);

  const CustomNoRowsOverlay = () => (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      No logs.
    </Box>
  );

  const renderAssetName = params => {
    const country = params.row.country;

    return isDomestic(country) ? (
      <Tooltip disableFocusListener arrow title={params.row.ticker} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.naver.com/item/main.naver?code=${params.row.ticker}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.value}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip disableFocusListener arrow title={params.value} PopperProps={{ sx: tooltipStyle }}>
        <Link
          // color='primary.light'
          underline='none'
          href={`https://finance.yahoo.com/quote/${params.row.ticker}?p=${params.row.ticker}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.row.ticker}
        </Link>
      </Tooltip>
    );
  };

  const renderPriceHeader = params => (
    <Tooltip disableFocusListener arrow title='Bought or sold price' PopperProps={{ sx: tooltipStyle }}>
      <Typography>{params.colDef.headerName}</Typography>
    </Tooltip>
  );

  const renderTradeStatus = params => {
    return (
      <>
        {params.value === 'SELL' ? (
          <Chip label='SELL' color='success' sx={{ width: '62px' }} />
        ) : (
          <Chip label='BUY' color='error' sx={{ width: '62px' }} />
        )}
      </>
    );
  };

  const rows = [];
  const columns = [
    {
      field: 'tradeDate',
      headerName: 'Date',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      sortable: true
    },
    {
      field: 'assetName',
      headerName: 'Asset',
      headerAlign: 'center',
      align: 'center',
      flex: isMobile ? 0.3 : 0.5,
      sortable: false,
      renderCell: renderAssetName
    },
    {
      field: 'assetPrice',
      headerName: 'Price',
      // description: 'Bought or sold price',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      sortable: false,
      renderHeader: renderPriceHeader
    },
    {
      field: 'assetQuantity',
      headerName: isMobile ? 'Qty' : 'Quantity',
      headerAlign: 'center',
      align: 'center',
      flex: isMobile ? 0.2 : 0.3,
      sortable: false
    },
    {
      field: 'tradeStatus',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: isMobile ? 0.2 : 0.3,
      sortable: true,
      renderCell: renderTradeStatus
    }
  ];

  trades?.sort((a, b) => {
    if (a.tradeDate > b.tradeDate) return -1;
    if (a.tradeDate < b.tradeDate) return 1;
    return 0;
  });

  trades?.forEach((trade, idx) => {
    const { tradeDate, assetName, assetPrice, assetQuantity, tradeStatus, ticker, country } = trade;

    const data = {
      id: idx + 1,
      tradeDate: !isMobile ? moment(tradeDate).format('MM/DD/YYYY HH:mm') : moment(tradeDate).format('MM-DD'),
      ticker,
      assetName: assetName,
      assetPrice: isDomestic(country)
        ? Number(Math.round(assetPrice)).toLocaleString('en-us')
        : `$${Number(assetPrice).toFixed(2).toLocaleString('en-us')}`,
      assetQuantity: Number(assetQuantity).toLocaleString('en-us'),
      tradeStatus: tradeStatus.toUpperCase(),
      country,
      trade
    };

    rows.push(data);
  });

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        dense
        autoHeight
        rows={rows}
        columns={columns}
        loading={isLoading}
        sortingOrder={['asc', 'desc']}
        disableRowSelectionOnClick
        disableColumnMenu
        columnVisibilityModel={{
          tradeDate: true,
          assetName: true,
          assetPrice: true,
          assetQuantity: true,
          tradeStatus: true
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[10]}
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
          '& .MuiDataGrid-columnHeader': { backgroundColor: '#001021' }
        }}
      />
    </div>
  );
};

export default TradeLogList;
