import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const NotFound = () => {
  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Box className='notfound'>
        <Box className='notfound-404'>
          <Typography component={'h1'}>404</Typography>
          <Typography component={'h2'}>Page not found</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
