import axios from 'axios';

export const searchTickers = async (token, tradingMode, ticker) => {
  try {
    const API_URL = `/api/${tradingMode.toLowerCase()}/assets/search/`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      }
    };

    const response = await axios.get(API_URL + ticker.toLowerCase(), config);

    return response.data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};
