import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setProfile, resetAuth } from '../../features/auth/authSlice';
import Spinner from '../Spinner';

import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, isLoading, isError, message } = useSelector(state => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful }
  } = useForm({
    defaultValues: {
      name: token.name || '',
      email: token.email || '',
      password: '',
      confirmPassword: ''
    }
  });
  const name = watch('name');
  const email = watch('email');
  const password = watch('password');

  useEffect(() => {
    if (isSubmitSuccessful) {
      toast.success('Profile updated!');
      reset({
        name,
        email,
        password: '',
        confirmPassword: ''
      });
    }

    if (isError) {
      toast.error(message);
    }

    if (!token) {
      return navigate('/login');
    }

    return () => {
      dispatch(resetAuth());
    };
  }, [token, isError, message, navigate, dispatch, isSubmitSuccessful, reset, name, email]);

  const onSubmit = data => {
    const profile = data.password === '' ? { name: data.name } : { name: data.name, password: data.password };
    dispatch(setProfile(profile));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={{ xs: 2, sm: 6 }}
        fontSize={{ xs: '1.3rem', sm: '1.5rem' }}
      >
        <ManageAccountsIcon fontSize={isMobile ? 'medium' : 'large'} /> Profile
      </Typography>
      <Typography variant={'body2'} component={'p'} textAlign={'center'} mb={6} color={'text.secondary'}>
        Review and update your personal information
      </Typography>

      <Box maxWidth={{ xs: '360px', sm: '600px' }} sx={{ width: '100%', margin: 'auto', typography: 'body2' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={4} gap={{ xs: '0.5rem', sm: '1rem' }} alignItems={'center'}>
            <TextField
              variant='standard'
              fullWidth
              label='Email'
              type='text'
              {...register('email')}
              helperText={'Email can not be changed'}
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              fullWidth
              label='Name'
              type='text'
              {...register('name', { required: 'Name is required' })}
              error={!!errors.name}
              helperText={errors.name?.message || 'Enter your full name'}
              sx={{
                '& input': {
                  fontSize: '14px'
                },
                '& input::label': {
                  fontSize: '14px'
                }
              }}
            />
            <TextField
              fullWidth
              label='New Password'
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              })}
              error={!!errors.password}
              helperText={errors.password?.message || 'Enter a new password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ '& .MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <KeyOffIcon /> : <KeyIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              fullWidth
              label='Confirm New Password'
              type={showPassword ? 'text' : 'password'}
              {...register('confirmPassword', {
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                },
                validate: value => value === password || 'Passwords do not match'
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message || 'Enter the new password again'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ '& .MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <KeyOffIcon /> : <KeyIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Button type='submit' variant='contained' color='primary' fullWidth disabled={!isDirty}>
              Save
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};

export default Profile;
