import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMyFunds, resetAuth } from '../../features/auth/authSlice';
import { toast } from 'react-toastify';
import { defaultFunds, getCurrentDate } from '../../lib/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddFund = ({ asset }) => {
  const dispatch = useDispatch();

  const { myFunds } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);
  const [selectedFund, setSelectedFund] = useState(0);
  const [isAdded, setIsAdded] = useState(false);

  const myFundList = myFunds || defaultFunds;

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChange = e => {
    setSelectedFund(e.target.value);
  };

  const onAdd = () => {
    const newFunds = JSON.parse(JSON.stringify(myFundList));
    const { dateUTC } = getCurrentDate();
    const newAsset = {
      name: asset.name,
      ticker: asset.ticker,
      country: asset.marketCode ? 'USA' : 'KOREA',
      marketCode: asset.marketCode || null,
      priceAt: asset.price,
      addedAt: dateUTC
    };

    const tickers = newFunds[selectedFund].assets.map(asset => asset.ticker);

    if (!tickers.includes(newAsset.ticker)) {
      newFunds[selectedFund].assets.push(newAsset);
      dispatch(setMyFunds(newFunds));
      setIsAdded(true);
    } else {
      // console.log(`[FAIL] ${newAsset.name} already exists in ${newFunds[selectedFund].name}`);
    }
  };

  useEffect(() => {
    if (isAdded) {
      toast.success(`${asset.name} added successfully`);
      setIsAdded(false);
    }

    return () => {
      dispatch(resetAuth());
    };
  }, [isAdded, dispatch, asset.name]);

  return (
    <>
      <Button variant='outlined' sx={{ padding: '0', minWidth: 0, width: '24px', height: '24px' }}>
        <AddIcon fontSize='small' onClick={handleOpenDialog} />
      </Button>

      <Dialog
        maxWidth={'md'}
        PaperProps={{ sx: { width: '400px' } }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
          }
        }}
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle textAlign={'center'}>Add Asset</DialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: '1rem auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <DialogContentText fontSize={14}>
            Select a personal fund to add <span style={{ fontWeight: '700', color: '#90caf9' }}>{asset.name}</span> to.
          </DialogContentText>
          <Box
            component={'fieldset'}
            display={'grid'}
            border='1px solid rgba(255, 255, 255, 0.12)'
            borderRadius='6px'
            fontSize={'14px'}
            my={2}
            p={3}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='Premium Logic'
                name='myFundList'
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  alignItems: 'center',
                  placeItems: 'center'
                }}
                value={selectedFund}
                onChange={handleChange}
              >
                <Box width={'auto'} display={'flex'} flexDirection={'column'}>
                  {myFundList.map((fund, idx) => (
                    <FormControlLabel key={idx} label={fund.name} value={idx} control={<Radio size='small' />} />
                  ))}
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => {
              onAdd();
              handleCloseDialog();
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddFund;
